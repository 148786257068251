import { Toolbar, ToolbarDivider, makeStyles, tokens } from '@fluentui/react-components'
import { track, useEditor } from 'tldraw'
import { AppNotifyVersion } from '../../app/notify/version'
import { useEditorToolToolbar } from '../tool/toolbar'
import { EditorToolbarHistory } from './history'
import { EditorToolbarNavigate } from './navigate'
import { EditorToolbarZoom } from './zoom'

const useStyles = makeStyles({
  toolbar: {
    minWidth: 'max-content',
    gap: tokens.spacingHorizontalXXS,
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
})

export const EditorToolbar = track((): JSX.Element => {
  const s = useStyles()
  const editor = useEditor()
  const tool = useEditorToolToolbar({ editor })

  return (
    <Toolbar size="small" className={s.toolbar} {...tool}>
      <EditorToolbarNavigate />
      <ToolbarDivider />
      <EditorToolbarHistory />
      <div className={s.banner}>
        <AppNotifyVersion />
      </div>
      <EditorToolbarZoom />
    </Toolbar>
  )
})
