import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AIModelOutputDuctVentcap } from '../../util/data/server'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { mergePredictFetchOutput } from '../fetch/merge'
import type { PredictFetch } from '../fetch/type'
import { parsePredictPieces } from '../parse/piece'
import { parsePredictPipelines } from '../parse/pipeline'
import type { PredictModelBase } from './type'

const fetch: PredictFetch = async (input) => {
  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const raw = await server.predictEquipmentsByAi({
    image: input.area.blob,
    model: 'DuctVentcap',
    pageID: input.page,
    sessionID: input.session,
    cropping: JSON.stringify(cropArea),
  })
  const output = raw.output as AIModelOutputDuctVentcap

  const pipelines = parsePredictPipelines({
    pipelines: output.pipelines.map(pipeline => ({
      diameters: pipeline.diameters,
      lines: pipeline.lines,
      type: null,
      vertical_segments: null,
    })),
    equipFallback: ATTR_EQUIP_VALUES.ROUND_DUCT,
    transform: input.area.globalise,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  const boxes = parsePredictPieces({
    boxes: output.ventcaps,
    transform: input.area.globalise,
    fallbackEquip: ATTR_EQUIP_VALUES.VENT_CAP,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  return mergePredictFetchOutput([pipelines, boxes])
}

export const PredictModelDuct = {
  value: 'duct',
  label: t('predict.model.duct'),
  system: 'mechanical',
  sample: false,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
  aiParams: false,
} as const satisfies PredictModelBase
