import { Card, TabList, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { AnnotPanel } from '../../annot/panel/panel'
import { PageListPanel } from '../../page/list/panel'
import { useSetting } from '../../setting/setting'
import { makeTabButton } from '../../ui/tab'
import { t } from '../../util/intl/t'
import type { AppPrimaryTab } from './tab'
import { appPrimaryTabSchema } from './tab'

const TabButton = makeTabButton<AppPrimaryTab>()

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...(shorthands.gap(tokens.spacingVerticalXS)),
    height: '100%',
  },
  card: {
    height: '100%',
    ...(shorthands.overflow('auto')),
  },
})

export function AppPrimary(): ReactElement {
  const s = useStyles()

  const { setting, updateSetting } = useSetting()

  return (
    <div className={s.container} draggable={false}>
      <TabList
        size="small"
        selectedValue={setting.primary}
        onTabSelect={(_event, data) => {
          const primary = appPrimaryTabSchema.parse(data.value)
          updateSetting({ primary })
        }}
        reserveSelectedTabSpace
      >
        <TabButton value="result">{t('app.tab.result')}</TabButton>
        <TabButton value="list">{t('app.tab.list')}</TabButton>
      </TabList>
      <Card appearance="filled" size="small" className={s.card}>
        {setting.primary === 'result' ? <AnnotPanel /> : null}
        {setting.primary === 'list' ? <PageListPanel /> : null}
      </Card>
    </div>
  )
}
