import { Box } from 'tldraw'
import { createPieceCheckAttr, createPieceCheckShape } from '../../annot/piece/check/create'
import { randomAnnotShapeColor } from '../../annot/shape/color'
import type { AttrEquipValue } from '../../attr/field/equip/value'
import type { AIOutputLabeledBox } from '../../util/data/server'
import { groupBy } from '../../util/web/object'
import type { PredictFetchCropTransform } from '../fetch/crop'
import type { PredictFetchOutput } from '../fetch/type'
import { parsePredictEquip } from './equip'
import { parsePredictShape } from './shape'
import { parsePredictType } from './type'

export function parsePredictPieces(props: {
  boxes: AIOutputLabeledBox[]
  transform: PredictFetchCropTransform
  fallbackEquip: AttrEquipValue
  aiPredictionRemaining: number
}): PredictFetchOutput {
  const { boxes, transform, fallbackEquip, aiPredictionRemaining } = props

  const output: PredictFetchOutput = { attrs: {}, shapes: [], aiPredictionRemaining }

  // AI's pieces are not grouped, but we can and should manually group them by
  // their diameter to put them into clusters.
  const boxGroups = groupBy({
    array: boxes,
    getKey: box => box.label?.equipment_diameter ?? '',
  })

  Object
    .values(boxGroups)
    .forEach((boxGroup) => {
      // Important: create a new group and color for the whole box group,
      // not for each individual box.
      const group = crypto.randomUUID()
      const color = randomAnnotShapeColor()

      output.attrs[group] = {
        ...(createPieceCheckAttr()),
        shape: parsePredictShape(boxGroup),
        type: parsePredictType({ boxes: boxGroup }),
        equip: parsePredictEquip(boxGroup) ?? fallbackEquip,
      }

      boxGroup.forEach((aiBox) => {
        const { height, width, x, y } = aiBox.box
        const local = new Box(x, y, width, height)
        const global = transform.box(local)

        const box = Box.FromCenter(global, { x: 10, y: 10 })
        const piece = createPieceCheckShape({
          box,
          color,
          group,
          id: null,
          interactive: 'ByAI',
        })
        output.shapes.push(piece)
      })
    })

  return output
}
