import { ToolbarButton } from '@fluentui/react-components'
import { AppFolder20Regular, Apps20Regular } from '@fluentui/react-icons'
import { track, useEditor } from 'tldraw'
import { useAttrs } from '../../attr/state/context'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'
import { groupBy } from '../../util/web/object'
import { getStrict } from '../../util/web/primitive'
import { isPieceShape } from '../piece/shape'
import { isSegmentShape } from '../segment/shape'
import { randomAnnotShapeColor } from '../shape/color'
import type { AnnotShape } from '../shape/shape'
import { isAnnotShape } from '../shape/shape'

export const AnnotToolbarExtract = track((): JSX.Element => {
  const { toast } = useToast()
  const editor = useEditor()
  const { attrs, setAttrs } = useAttrs()

  const extractToNewGroup = (prevShapes: AnnotShape[]) => {
    if (prevShapes.length === 0)
      return

    const groupId = crypto.randomUUID()
    const color = randomAnnotShapeColor()

    const nextShapes = prevShapes.map(shape => ({
      ...shape,
      props: { ...shape.props, color },
      meta: { ...shape.meta, group: groupId },
    }))

    // There are brief moments when a "group ID" is defined without its
    // corresponding attribute fields, for example, when a shape is created but
    // its `setAttrs` has not settled yet.
    //
    // However, when handling user events, it's expected that the attribute fields
    // are already settled.
    const prevGroup = getStrict(prevShapes.at(0)).meta.group
    const attr = getStrict(attrs[prevGroup])

    setAttrs(prev => ({ ...prev, [groupId]: attr }))
    editor.updateShapes([...nextShapes])
  }

  const extractToSingleGroup = () => {
    const shapes = editor.getSelectedShapes().filter(isAnnotShape)
    if (shapes.length === 0)
      return void toast(t('annot.toolbar.extract-none'))

    editor.mark()
    extractToNewGroup(shapes.filter(isPieceShape))
    extractToNewGroup(shapes.filter(isSegmentShape))
  }

  const extractToMultipleGroups = () => {
    const shapes = editor.getSelectedShapes().filter(isAnnotShape)
    if (shapes.length === 0)
      return void toast(t('annot.toolbar.extract-none'))

    editor.mark()

    const pieceGroups = groupBy({
      array: shapes.filter(isPieceShape),
      getKey: shape => shape.meta.group,
    })
    Object.values(pieceGroups).forEach(extractToNewGroup)

    const segmentGroups = groupBy({
      array: shapes.filter(isSegmentShape),
      getKey: shape => shape.meta.group,
    })
    Object.values(segmentGroups).forEach(extractToNewGroup)
  }

  return (
    <>
      <ToolbarTooltip
        relationship="description"
        content={t('annot.toolbar.extract-single')}
      >
        <ToolbarButton
          icon={<AppFolder20Regular />}
          onClick={extractToSingleGroup}
        >
          {t('annot.toolbar.extract-single')}
        </ToolbarButton>
      </ToolbarTooltip>
      <ToolbarTooltip
        relationship="description"
        content={t('annot.toolbar.extract-multiple')}
      >
        <ToolbarButton
          icon={<Apps20Regular />}
          onClick={extractToMultipleGroups}
        >
          {t('annot.toolbar.extract-multiple')}
        </ToolbarButton>
      </ToolbarTooltip>
    </>
  )
})
