// Empty comment to keep dict file have the same line numbers.

export const INTL_DICT_EN = {
  'annot.color.blue': 'Blue',
  'annot.color.green': 'Green',
  'annot.color.lightBlue': 'Light blue',
  'annot.color.lightRed': 'Light red',
  'annot.color.lightViolet': 'Light purple',
  'annot.color.orange': 'Orange',
  'annot.color.red': 'Red',
  'annot.color.violet': 'Purple',
  'annot.color.yellow': 'Yellow',
  'annot.group.menu.hide-equipments': 'Hide other equipments',
  'annot.group.menu.hide': 'Hide other groups',
  'annot.group.menu.lock': 'Lock other groups',
  'annot.panel.action.title': 'Show/Hide:',
  'annot.panel.all.show': 'Show all',
  'annot.panel.all.unlock': 'Unlock all',
  'annot.panel.empty': 'There are no annotations yet. To get started, click “Ask AI”.',
  'annot.panel.selection.hide': 'Hide selection',
  'annot.panel.selection.lock': 'Lock',
  'annot.piece.help-1': 'You are annotating pieces.',
  'annot.piece.help-2': 'To add a piece, click on the canvas:',
  'annot.piece.help-3': 'The piece will be added to the selected group on the left panel.',
  'annot.piece.help-4': 'To create a new group, unselect any group, then click on the canvas.',
  'annot.piece.help-5': 'To edit a piece, switch to the Select tool, then click on the piece.',
  'annot.segment.fixed': 'Fixed',
  'annot.segment.help-1': 'You are annotating segments.',
  'annot.segment.help-2': 'To start a pipeline, left-click on the starting point, then click on the next point to create a segment. You can keep adding segments by clicking on additional points. To finish, right-click or press the Esc key.',
  'annot.segment.help-3': 'The segment will be added to the selected pipeline on the left panel.',
  'annot.segment.help-4': 'To edit a segment, switch to the Select tool, then click on the segment.',
  'annot.segment.label-size': 'Font size:',
  'annot.segment.label': 'Pipe information:',
  'annot.segment.none': 'None',
  'annot.segment.scale': 'Scale',
  'annot.segment.shape': 'Diameter',
  'annot.segment.size': 'Length',
  'annot.sync.failed': 'Saving annotations failed.',
  'annot.toolbar.cut': 'Cut',
  'annot.toolbar.delete-none': 'Cannot delete: Nothing selected',
  'annot.toolbar.delete': 'Delete',
  'annot.toolbar.duplicate-none': 'Nothing to duplicate',
  'annot.toolbar.duplicate': 'Duplicate',
  'annot.toolbar.extension': 'Extension',
  'annot.toolbar.extract-multiple': 'Extract',
  'annot.toolbar.extract-none': 'Cannot extract: Nothing selected',
  'annot.toolbar.extract-single': 'Group',
  'annot.toolbar.head': 'Head',
  'annot.toolbar.piece': 'Piece',
  'annot.toolbar.segment': 'Segment',
  'annot.toolbar.vertical-down': 'Vertical down',
  'annot.toolbar.vertical-up': 'Vertical up',
  'annot.total.length-unit': 'm',
  'annot.total.piece-unit': 'pieces',
  'annot.warning.length-body': 'Some lengths may be wrong. To set page scale, go to the “Page” tab.',
  'annot.warning.length-title': 'Page scale is not set.',
  'app.error.forbidden-desc.1': 'You lack permission to access this page.',
  'app.error.forbidden-desc.2': 'Please contact the owner of the organization or construction project to send you an invitation.',
  'app.error.help': 'Please refresh the page or contact us',
  'app.error.limit': 'The number of downloads exceeds the limitation.',
  'app.error.title': 'There was an error loading the page',
  'app.error.unauthorized-desc.1': 'You are not authorized to view this page.',
  'app.error.unauthorized-desc.2': 'To continue, please login again.',
  'app.notify.hide': 'Hide',
  'app.notify.learn-more': 'Learn more',
  'app.notify.new-version': 'New version: ',
  'app.tab.annotate': 'Annotate',
  'app.tab.attr': 'Properties',
  'app.tab.help': 'Tutorial',
  'app.tab.list': 'Page list',
  'app.tab.page': 'Page',
  'app.tab.predict': 'Ask AI',
  'app.tab.result': 'Result table',
  'app.tab.view': 'View',
  'app.theme.dark': 'Dark mode',
  'app.theme.light': 'Light mode',
  'app.toolbar.edit': 'Review',
  'app.toolbar.help': 'Help',
  'attr.c-area.mixed': '(Multiple areas)',
  'attr.c-module.mixed': '(Multiple modules)',
  'attr.color.mixed': '(Multiple colors)',
  'attr.equip.help': 'There are multiple equipments selected, so some fields are not visible. To adjust your selection by equipments, use the checkboxes in the Result Table.',
  'attr.equip.mixed': '(Multiple equipments)',
  'attr.field.c-area': 'Construction area',
  'attr.field.c-module': 'Module',
  'attr.field.color': 'Color',
  'attr.field.equip': 'Equipment',
  'attr.field.material-1': 'Material',
  'attr.field.material-2': 'Material name',
  'attr.field.pipe-type': 'Pipe type',
  'attr.field.thickness': 'Thickness',
  'attr.field.type': 'Type',
  'attr.insul-thickness.mixed': '(Mixed thicknesses)',
  'attr.material-1.mixed': '(Multiple materials)',
  'attr.material-2.mixed': '(Multiple names)',
  'attr.panel.empty': 'Select annotations to see their properties here.',
  'attr.panel.help': 'Changes are also applied to other annotations in the same groups with the selected annotations. To apply changes to the selected annotations only, use "Extract" on the toolbar.',
  'attr.pipe-type.main': 'Main',
  'attr.pipe-type.mixed': '(Mixed pipe types)',
  'attr.pipe-type.sub': 'Sub',
  'attr.shape.box': ' (Width / height / length)',
  'attr.shape.circle': ' (Diameter)',
  'attr.shape.custom': ' (Custom)',
  'attr.shape.electric': ' (Kilowatt)',
  'attr.shape.insulation': ' (Thickness)',
  'attr.shape.label': 'Size',
  'attr.shape.mixed': '(Multiple sizes)',
  'attr.shape.rectangle': ' (Width / length)',
  'attr.shape.refrigerant': ' (Liquid / suction)',
  'attr.type.FD': 'FD',
  'attr.type.mixed': '(Multiple types)',
  'attr.type.NonFD': 'Non FD',
  'editor.history.redo-none': 'No more actions to redo',
  'editor.history.redo': 'Redo',
  'editor.history.undo-none': 'No more actions to undo',
  'editor.history.undo': 'Undo',
  'editor.level.error-exact': 'Already at 100%',
  'editor.level.error-max': 'Already at maximum zoom',
  'editor.level.error-min': 'Already at minimum zoom',
  'editor.level.in': 'Zoom in',
  'editor.level.out': 'Zoom out',
  'editor.level.reset': '100%',
  'editor.navigate.hand': 'Hand / Move / Pan',
  'editor.navigate.select': 'Select',
  'editor.navigate.zoom': 'Zoom',
  'editor.target.error-empty': 'No annotations yet',
  'editor.target.error-none': 'Nothing selected',
  'editor.target.page': 'Page',
  'editor.target.selection': 'Selection',
  'editor.target.shapes': 'All annotations',
  'editor.target.title': 'Zoom to',
  'flag': '🇺🇸',
  'help.panel.hide': 'Hide tutorial',
  'help.panel.show': 'Show tutorial',
  'insul.field.material-1': 'Exterior material',
  'insul.field.material-2': 'Insulation material',
  'insul.header.auto-help': 'Auto Insulation: When the equipment\'s properties are selected, insulation is enabled automatically if necessary, with appropriate material.',
  'insul.header.auto-title': 'Auto',
  'insul.header.enabled': 'With insulation',
  'insul.header.title': 'Insulation',
  'insul.material-1.mixed': '(Multiple materials)',
  'insul.material-2.mixed': '(Multiple names)',
  'page.field.category': 'Category',
  'page.field.construction': 'Construction',
  'page.field.name': 'Page',
  'page.field.predict-limit': 'Ask AI limit',
  'page.field.view-limit': 'View limit',
  'page.info.prefix': 'Category: ',
  'page.limit.remaining': ' remaining',
  'page.print.button': 'Print',
  'page.print.in-progress': 'Print is in progress',
  'paper.scale.cancel': 'Cancel',
  'paper.scale.help-1': 'To ensure your document scales correctly when changing paper sizes (for example, from A3 to A1), the scale is set to adjust automatically (like from 1:100 to 1:50). This should work well with most PDF files.',
  'paper.scale.help-2': 'In rare cases where a PDF file has incorrect size, you can adjust the scale manually below:',
  'paper.scale.paper': 'At paper size:',
  'paper.scale.scale': 'The scale is:',
  'paper.scale.submit': 'Update',
  'paper.scale.title': 'Adjust paper and scale',
  'predict.area.select': 'Select area…',
  'predict.button.add-water-source': 'Add water source',
  'predict.fetch.action': 'Start AI',
  'predict.fetch.crop-samples.invalid': 'Please review all the samples and the selected areas to start with AI. Ensure that each sample is smaller than the selected area.',
  'predict.fetch.image-size-limit': 'The selected area is too large. Please select a smaller area to start with AI.',
  'predict.fetch.limit': 'The number of AI Prediction requests exceeds the limitation. Please duplicate the page and try again on a new page.',
  'predict.fetch.no-area': 'Please select an area',
  'predict.fetch.no-model': 'Please select an AI model',
  'predict.fetch.no-sample': 'Please select at least 1 sample',
  'predict.fetch.pending': 'Ask AI is in progress',
  'predict.fetch.required-pdf-vector': `Selected area doesn't contain necessary information for auto pipe detection. If the selection was right, please upload a higher quality PDF exported from CAD tools then try again.`,
  'predict.fetch.sample-fire-alarm.invalid': 'Please add at least 2 samples to each group to start with AI.',
  'predict.fetch.success': 'Ask AI is completed',
  'predict.fetch.water-source.invalid': 'Please add at least 1 water source and ensure it belongs to the selected area before starting with AI.',
  'predict.fire-area-help.1': 'You are asking AI for annotating assistance.',
  'predict.fire-area-help.2': 'Step 2: Drag on the canvas to select an area to annotate:',
  'predict.fire-area-help.3': 'You can select multiple areas. For each area, use the panel on the right to set the common properties for all annotations inside in advance.',
  'predict.fire-area-help.4': 'When you are ready, click Start AI to start. Depend on the size and complexity of your areas, this may take from 10 seconds to 2 minutes.',
  'predict.fire-help.1': 'You are asking AI for annotating assistance.',
  'predict.fire-help.2': 'Step 1: Drag on the canvas to select a sample to annotate. The selected area should be as close to the object as possible to ensure accurate sampling.',
  'predict.fire-help.3': `When you are ready, click Select area to proceed the next step.`,
  'predict.fire-pipe-help.1': 'You are asking AI for annotating assistance.',
  'predict.fire-pipe-help.2': 'First, you might need to manually correct the annotations by adding or deleting as necessary.',
  'predict.fire-pipe-help.3': 'To add a piece, click on the canvas:',
  'predict.fire-pipe-help.4': 'The piece will be added to the selected piece on the left panel.',
  'predict.fire-pipe-help.5': 'Then, drag on the canvas to select an area to annotate:',
  'predict.fire-pipe-help.6': 'You can select multiple areas. For each area, use the panel on the right to set the common properties for all annotations inside in advance.',
  'predict.fire-pipe-help.7': 'When you are ready, click Start AI to start. Depend on the size and complexity of your areas, this may take from 10 seconds to 2 minutes.',
  'predict.fire-size-help.1': 'You are asking AI for annotating assistance.',
  'predict.fire-size-help.2': 'First, you might need to manually correct the annotations by adding or deleting as necessary.',
  'predict.fire-size-help.3': 'To add a segment, drag on the canvas:',
  'predict.fire-size-help.4': 'The segment will be added to the selected pipeline on the left panel.',
  'predict.fire-size-help.5': 'Next, drag on the canvas to select an area to annotate:',
  'predict.fire-size-help.6': 'You can select multiple areas. For each area, use the panel on the right to set the common properties for all annotations inside in advance.',
  'predict.fire-size-help.7': 'When you are ready, click Start AI to start. Depend on the size and complexity of your areas, this may take from 10 seconds to 2 minutes.',
  'predict.group-sample.group': 'Group',
  'predict.help.1': 'You are asking AI for annotating assistance.',
  'predict.help.2': 'First, drag on the canvas to select an area to annotate:',
  'predict.help.3': 'You can select multiple areas. For each area, use the panel on the right to set the common properties for all annotations inside in advance.',
  'predict.help.4': 'When you are ready, click Start AI to start. Depend on the size and complexity of your areas, this may take from 10 seconds to 2 minutes.',
  'predict.model.action': 'AI model',
  'predict.model.drain': 'Mechanical Piping',
  'predict.model.duct': 'HVAC',
  'predict.model.fire-alarm': 'Fire Alarm',
  'predict.model.fire-head': 'Sprinkler Head',
  'predict.model.fire-pipe-diameter': 'Fire Protection Sizing',
  'predict.model.fire-pipe': 'Fire Protection Piping',
  'predict.model.sanitary': 'Sanitary Piping',
  'predict.params.add-formula': 'Add formula',
  'predict.params.add-param': 'Add param',
  'predict.params.apply': 'Apply',
  'predict.params.cancel-button': 'Cancel',
  'predict.params.custom-rule': 'Custom rule',
  'predict.params.custom': 'Custom',
  'predict.params.delete-param': 'Delete',
  'predict.params.diameter': 'Diameter',
  'predict.params.equip': 'Equipment',
  'predict.params.exchange-value': 'Exchange value',
  'predict.params.formulas': 'Formulas',
  'predict.params.global-rules': 'Global rules',
  'predict.params.hydrant-equip': 'Hydrant equipment',
  'predict.params.next-button': 'Next',
  'predict.params.number-head-from': 'Min',
  'predict.params.number-head-to': 'Max',
  'predict.params.number-of-heads': 'Number of heads',
  'predict.params.params': 'Parameters',
  'predict.params.rule-detail': 'Rule detail',
  'predict.params.rule': 'Rule',
  'predict.params.select-hint-1': 'You can skip this setting, and the system will use the default rule.',
  'predict.params.select-hint-2': 'If you prefer, you can customize a rule for a specific area by modifying it.',
  'predict.pricing-plan.agree': 'Agree',
  'predict.pricing-plan.close': 'Close',
  'predict.pricing-plan.contact-owner': ' The number of AI prediction requests exceeds the limitation. To continue using this feature, please contact the owner of the organization to purchase more blocks for use.',
  'predict.pricing-plan.exchange-page-successful': 'One page has been subtracted from page pool. You can now proceed with your requests, please use "Start AI" button to continue.',
  'predict.pricing-plan.purchase-overage': 'The number of AI prediction requests exceeds the limitation. To continue using this feature, one page from overage page limit will be purchased.',
  'predict.pricing-plan.reached-limit': 'Prediction limit reached',
  'predict.pricing-plan.subtract-page': 'The number of AI prediction requests exceeds the limitation. To continue using this feature, you need to subtract 1 page from your page pool. Do you agree to proceed?',
  'predict.pricing-plan.use-next-month': ' The number of AI prediction requests exceeds the limitation. You can not make any more requests on this page. Please wait until next month to continue.',
  'predict.sample.select': 'Select sample…',
  'predict.system.fire': 'Fire Protection',
  'predict.system.mechanical': 'Mechanical',
  'predict.system.plumbing': 'Plumbing',
  'predict.water-source.help-1': 'Next, you need to add water source.',
  'predict.water-source.help-2': 'To add a water source, click on the canvas:',
  'predict.water-source.help-3': 'When you are ready, click to Select area to proceed the next step.',
  'scale.error.none': 'Cannot set scale: No distance defined',
  'scale.error.number': 'Cannot set scale: Unknown length',
  'scale.help.1': 'You are setting the page scale by measuring a distance on the canvas.',
  'scale.help.2': 'First, drag on canvas to define a distance:',
  'scale.help.3': 'Second, enter the actual length in the highlighted box on the toolbar. The length should be in millimeters.',
  'scale.help.4': 'Finally, press "Enter" to set the scale.',
  'scale.idle.title': 'Scale:',
  'scale.measure.length': 'Length (mm):',
  'scale.menu.adjust': 'Adjust…',
  'scale.menu.measure': 'Measure…',
  'scale.menu.not-set': 'Not set',
  'segment.geo.length': 'Length (m):',
}
// Do not use "as const" here,
// as we will use this to enforce the type of the "ja" dict.
