import type { GriffelStyle } from '@fluentui/react-components'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from './animation'

/**
 * To move. We need to separate this from "scale" to avoid they are applied
 * on the same "transform", which is very hard to get right.
 */
function makeContainer(props: {
  start: GriffelStyle
  end: GriffelStyle
}): GriffelStyle {
  const { start, end } = props

  return {
    width: tokens.spacingHorizontalXL,
    aspectRatio: 1,
    ...(makeHelpAnimation([{
      states: { off: { opacity: 0 }, on: { opacity: 0.75 } },
      keyframes: [[0, 'off'], [1, 'on'], [8, 'on'], [9, 'off'], [10, 'off']],
    }, {
      states: { start, end },
      keyframes: [[0, 'start'], [2, 'start'], [6, 'end'], [10, 'end']],
    }])),
  }
}

const useStyles = makeStyles({
  line: makeContainer({
    start: { transform: 'translateX(-75px)' },
    end: { transform: 'translateX(75px)' },
  }),
  rect: makeContainer({
    start: { transform: 'translateX(-75px) translateY(-50px)' },
    end: { transform: 'translateX(75px) translateY(50px)' },
  }),
  indicator: {
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colorBrandStroke2,
    ...(shorthands.border('2px', 'solid', tokens.colorBrandForeground1)),
    ...(shorthands.borderRadius('100%')),
    ...(makeHelpAnimation([{
      states: {
        L: { transform: 'scale(1)', backgroundColor: tokens.colorBrandStroke2 },
        S: { transform: 'scale(0.75)', backgroundColor: tokens.colorBrandForeground1 },
      },
      keyframes: [[0, 'L'], [1, 'S'], [2, 'L'], [6, 'L'], [7, 'S'], [8, 'S'], [9, 'L']],
    }])),
  },
})

export function HelpPointTranslate(props: {
  target: 'line' | 'rect'
}): JSX.Element {
  const { target } = props

  const s = useStyles()

  return (
    <div
      className={mergeClasses(
        target === 'line' ? s.line : '',
        target === 'rect' ? s.rect : '',
      )}
    >
      <div className={s.indicator} />
    </div>
  )
}
