import { Button, Caption1, Caption1Strong, Divider, Select, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Add16Regular, Delete16Regular, Toolbox16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { LocaledEquipmentClass } from '../../../generated/server'
import { Field } from '../../ui/field'
import { t } from '../../util/intl/t'
import { PredictParamFormulaForm } from './formula'
import type { AIParams, AIParamsFormRule } from './type'

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    margin: `${tokens.spacingHorizontalM} 0 0 0`,
  },
  formulas: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalXS,
  },
  gapM: {
    rowGap: tokens.spacingHorizontalM,
  },
  select: {
    '> select': {
      minWidth: 'auto',
      paddingRight: 0,
    },
  },
  title: {
    margin: `${tokens.spacingHorizontalS} 0`,
  },
  alignCenter: {
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: `${tokens.spacingHorizontalM}`,
  },
})

export function PredictParamRuleForm(props: {
  form: UseFormReturn<AIParams>
  equipmentClasses: LocaledEquipmentClass[]
  updateAreaShape: (params: AIParams) => void
}): ReactElement {
  const { form, equipmentClasses, updateAreaShape } = props

  const rules = form.watch('rules')
  const values = form.getValues()

  const s = useStyles()

  const updateRules = (index: number, rule: AIParamsFormRule) => {
    const next = [...rules]
    next[index] = rule
    form.setValue('rules', next)
    updateAreaShape({ ...values, rules: next })
  }

  const addRule = () => {
    const next = [
      ...rules,
      {
        id: crypto.randomUUID(),
        equipmentClass: '0',
        formulas: [{
          id: crypto.randomUUID(),
          from: '',
          to: '',
          diameter: '',
        }],
      },
    ]
    form.setValue('rules', next)
    updateAreaShape({ ...values, ...next })
  }

  const removeRule = (index: number) => {
    if (rules.length === 1)
      return
    const next = [...rules]
    next.splice(index, 1)
    form.setValue('rules', next)
    updateAreaShape({ ...values, ...next })
  }

  return (
    <div className={mergeClasses(s.gapM, s.column)}>
      {rules.map((rule, index) => {
        return (
          <div key={`${rule.equipmentClass}${index}`} className={mergeClasses(s.column)}>
            <div className={mergeClasses(s.row, s.alignCenter)}>
              <Caption1Strong>{`${t('predict.params.params')} ${index + 1}`}</Caption1Strong>
              {rules.length !== 1 && (
                <Button
                  icon={<Delete16Regular />}
                  onClick={() => {
                    removeRule(index)
                  }}
                  size="small"
                  appearance="subtle"
                />
              )}
            </div>

            <div className={mergeClasses(s.gapM, s.column)}>
              <div>
                <Field label={t('predict.params.equip')} icon={icon => <Toolbox16Filled className={icon.className} />}>
                  <Select
                    value={rule.equipmentClass.toString()}
                    onChange={(_e, data) => {
                      if (data.value) {
                        rule = { ...rule, equipmentClass: data.value }
                        updateRules(index, rule)
                      }
                    }}
                    appearance="filled-darker"
                    className={s.select}
                    name={`rule.equip[${index}]`}
                  >
                    {equipmentClasses.map((equip, _index) => (
                      <option key={`${equip.equipmentClass}-locale`} value={equip.equipmentClass.toString()}>
                        {equip.localed}
                      </option>
                    ))}
                  </Select>
                </Field>
              </div>

              <div className={s.formulas}>
                <div className={s.row}>
                  <Caption1>{t('predict.params.formulas')}</Caption1>
                  <Button
                    appearance="subtle"
                    icon={<Add16Regular />}
                    size="small"
                    onClick={() => {
                      const next = [...rule.formulas]
                      next.push({
                        id: crypto.randomUUID(),
                        from: '',
                        to: '',
                        diameter: '',
                      })
                      updateRules(index, { ...rule, formulas: next })
                    }}
                  />
                </div>
                <PredictParamFormulaForm formulas={rule.formulas} onChange={formulas => updateRules(index, { ...rule, formulas })} />
              </div>
            </div>

            {index < rules.length - 1
              ? (
                <div className={s.divider}>
                  <Divider />
                </div>
                )
              : null}
          </div>
        )
      },
      )}
      <Button
        icon={<Add16Regular />}
        size="small"
        onClick={() => addRule()}
      >
        {t('predict.params.add-param')}
      </Button>
    </div>
  )
}
