import { ToolbarButton } from '@fluentui/react-components'
import { SquareHintSparkles20Filled, SquareHintSparkles20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { useSetting } from '../../setting/setting'
import { t } from '../../util/intl/t'
import { PredictAreaTool } from './tool'

export const PredictAreaButton = track((props: {
  highlight: boolean
}): ReactElement => {
  const { highlight } = props

  const editor = useEditor()
  const { updateSetting, setting } = useSetting()

  return (
    <ToolbarButton
      appearance={highlight
        ? 'primary'
        : 'subtle'}
      icon={highlight
        ? <SquareHintSparkles20Filled />
        : <SquareHintSparkles20Regular />}
      onClick={() => {
        if (setting.predictModel === 'fire-pipe-diameter')
          updateSetting({ secondary: 'attr' })
        else
          updateSetting({ secondary: 'help' })
        editor.setCurrentTool(PredictAreaTool.id)
      }}
    >
      {t('predict.area.select')}
    </ToolbarButton>
  )
})
