import { Divider, Text, makeStyles, tokens } from '@fluentui/react-components'
import { Fragment, type ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import type { AnnotShape } from '../annot/shape/shape'
import { isAnnotShape } from '../annot/shape/shape'
import { isPredictAreaShape } from '../predict/area/shape'
import { PREDICT_MODELS } from '../predict/model/option'
import { PredictParamDetail } from '../predict/param/detail'
import { useSetting } from '../setting/setting'
import { t } from '../util/intl/t'
import { AttrForm } from './form'
import type { AttrValue } from './state/context'
import { useAttrs } from './state/context'

const useStyles = makeStyles({
  help: {
    color: tokens.colorNeutralForeground4,
  },
})

export const AttrPanel = track((): ReactElement => {
  const editor = useEditor()
  const all = useAttrs()
  const s = useStyles()
  const { predictModel } = useSetting().setting

  const selected = editor.getSelectedShapes()

  const shapes = selected.filter(isAnnotShape)

  const model = PREDICT_MODELS.find(m => m.value === predictModel) ?? null

  const area = selected.some(isPredictAreaShape)

  const attrs = shapes
    // We should handle missing attr flexibly here. There are short periods
    // during rendering where a new shape is created, along with its new group
    // ID, but the corresponding "setAttrs" is not settled yet.
    .map(shape => all.attrs[shape.meta.group] ?? null)
    .filter((attr): attr is AttrValue => attr !== null)

  const empty = (
    <Text className={s.help} size={200}>
      {t('attr.panel.empty')}
    </Text>
  )

  if (model && model.aiParams && area) {
    const selectedShape = editor.getOnlySelectedShape()
    return selectedShape ? <PredictParamDetail key={selectedShape.id} /> : empty
  }

  // "Attr form" only works when there's at least one annot selected,
  // so we need to gate keep this and only pass "attrs" when it's not empty.
  // See "attr/selection" to learn more.
  if (attrs.length === 0)
    return empty

  // Update shape interactive to ByBoth when user changes AI detected attributes
  const updateShapes = (update: Partial<AttrValue>, shapes: AnnotShape[]) => {
    // Currently, our AI models can predict "equip", "type" and "shape"
    const updateAttrs = Object.keys(update) as (keyof AttrValue)[]
    if (updateAttrs.every(attr => !['equip', 'type', 'shape'].includes(attr)))
      return
    // If all selected shapes interactive is ByManual or ByBoth, we don't need to update
    if (shapes.every(shape => shape.meta.interactive !== 'ByAI'))
      return
    // Update all selected shapes interactive to ByBoth
    editor.updateShapes(shapes.map((shape) => {
      if (shape.meta.interactive === 'ByAI')
        return { ...shape, meta: { ...shape.meta, interactive: 'ByBoth' } }
      return shape
    }))
  }

  return (
    <Fragment>
      <Text className={s.help} size={200}>{t('attr.panel.help')}</Text>
      <div>
        <Divider />
      </div>
      <AttrForm attrs={attrs} shapes={shapes} updateShapes={updateShapes} />
    </Fragment>
  )
})
