import type { ReactElement } from 'react'
import { MenuRadio } from '../../../ui/menu'
import { LocaleSchema } from '../t'
import { locale } from './type'

type DictMeta = {
  label: string
  value: string
  icon: string
}

const dict: Record<string, DictMeta> = {
  en: {
    label: 'English',
    value: 'en',
    icon: '🇺🇸',
  },
  ja: {
    label: '日本語',
    value: 'ja',
    icon: '🇯🇵',
  },
  vi: {
    label: 'Tiếng Việt',
    value: 'vi',
    icon: '🇻🇳',
  },
}

export function IntlLocaleMenu(): ReactElement {
  return (
    <MenuRadio
      value={locale}
      setValue={(value) => {
        window.localStorage.setItem('locale', value)
        window.location.reload()
      }}
      displayFallback={null}
      parse={LocaleSchema.parse}
      button={{
        appearance: 'subtle',
      }}
      options={Object.entries(dict).map(([key, value]) => ({
        label: `${value.icon} ${value.label}`,
        value: key,
      }),
      )}
    />
  )
}
