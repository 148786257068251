import type { BoxLike, Editor, TLShape, VecLike } from 'tldraw'
import { Box, Vec } from 'tldraw'
import { PAGE_PDF_SCALE } from '../../page/provider/pdf'
import { getCanvasBlob } from '../../util/web/canvas'

/**
 * Manual type because of OpenAPI's limitation.
 * See server's "predictEquipmentsByAi".
 *
 * Values are scaled. "scale" is attached for convenience.
 * It should always be the same as "PAGE_PDF_SCALE".
 */
type Source = {
  scale: number
  x: number
  y: number
  w: number
  h: number
}

type Transform = {
  vec: (prev: VecLike) => Vec
  box: (prev: BoxLike) => Box
  line: (prev: [VecLike, VecLike]) => [Vec, Vec]
}

export type { Transform as PredictFetchCropTransform }

function makeTransform(vec: Transform['vec']): Transform {
  return {
    vec,
    box: (prev) => {
      const points = Box.From(prev).corners.map(vec)
      return Box.FromPoints(points)
    },
    line: (prev) => {
      const [start, end] = prev
      return [vec(start), vec(end)]
    },
  }
}

export type PredictFetchCrop = {
  blob: Blob
  source: Source
  globalise: Transform
  localise: Transform
  group: string | null
}

async function cropPredictFetch(props: {
  shape: TLShape
  editor: Editor
  pdf: HTMLCanvasElement
}): Promise<PredictFetchCrop> {
  const { editor, shape, pdf } = props

  const box = editor.getShapePageBounds(shape)
  // Likely coding error, e.g., shape is not added to the page
  if (!box)
    throw new Error('No bounds found')

  const [S, a] = [PAGE_PDF_SCALE, box]
  const [x, y, w, h] = [a.x * S, a.y * S, a.w * S, a.h * S]
  const rect = new DOMRect(x, y, w, h)

  return {
    blob: await getCanvasBlob({ canvas: pdf, rect }),
    globalise: makeTransform((prev) => {
      const vec = Vec.From(prev)
      return vec.div(PAGE_PDF_SCALE).add(box)
    }),
    localise: makeTransform((prev) => {
      const vec = Vec.From(prev)
      return vec.sub(box).mul(PAGE_PDF_SCALE)
    }),
    source: { scale: S, x, y, w, h },
    group: shape.meta.group_template?.toString() ?? null,
  }
}

export function makeCropPredictFetch(props: {
  editor: Editor
  pdf: HTMLCanvasElement
}): (shape: TLShape) => Promise<PredictFetchCrop> {
  const { editor, pdf } = props
  return shape => cropPredictFetch({ shape, editor, pdf })
}

export function getPredictFetchCropShapes(props: {
  editor: Editor
  area: TLShape
}): TLShape[] {
  const { editor, area } = props

  const container = editor.getShapePageBounds(area)
  if (!container)
    throw new Error('No bounds found')

  return editor.getCurrentPageShapes().filter((shape) => {
    const box = editor.getShapePageBounds(shape)
    if (!box)
      throw new Error('No bounds found')
    return container.contains(box)
  })
}
