import type { TLDefaultColorStyle, TLShapeId, VecLike } from 'tldraw'
import { Box, createShapeId } from 'tldraw'
import type { Interactive, VerticalDirection } from '../../../util/data/server'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { SegmentVerticalPartial } from './shape'

export const SEGMENT_VERTICAL_SIZE = 10

export function createSegmentVerticalShape(props: {
  center: VecLike
  direction: VerticalDirection | null
  mm: number | null
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
  interactive: Interactive | null
}): SegmentVerticalPartial {
  const { center, direction, mm, color, group, id, interactive } = props

  const [w, h] = [SEGMENT_VERTICAL_SIZE, SEGMENT_VERTICAL_SIZE]
  const { x, y } = Box.FromCenter(center, { x: w, y: h })

  const vertical: SegmentVerticalPartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    props: {
      geo: 'triangle',
      dash: 'solid',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w,
      h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      interactive,
      annot: 'segment',
      segment: 'vertical',
      mm: mm ?? 0,
      direction: direction ?? 'up',
    },
  }

  return vertical
}
