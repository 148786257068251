import { Button, Input, makeStyles, tokens } from '@fluentui/react-components'
import { Dismiss16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { Field } from '../../ui/field'
import { t } from '../../util/intl/t'
import type { AIParamsFormFormula } from './type'

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  formula: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalXS,
    width: '100%',
    alignItems: 'end',
  },
  formulas: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalXS,
  },
  input: {
    width: '56px',
  },
  gapM: {
    rowGap: tokens.spacingHorizontalM,
  },
  select: {
    '> select': {
      minWidth: 'auto',
      paddingRight: 0,
    },
  },
  title: {
    margin: `${tokens.spacingHorizontalS} 0`,
  },
  alignCenter: {
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: `${tokens.spacingHorizontalM}`,
  },
})

export function PredictParamFormulaForm(props: {
  formulas: AIParamsFormFormula[]
  onChange: (formulas: AIParamsFormFormula[]) => void
}): ReactElement {
  const { formulas, onChange } = props

  const s = useStyles()

  const updateFormulas = (index: number, formula: AIParamsFormFormula) => {
    const next = [...formulas]
    next[index] = formula
    onChange(next)
  }

  const removeFormula = (index: number) => {
    if (formulas.length === 1)
      return
    const next = [...formulas]
    next.splice(index, 1)
    onChange(next)
  }

  return (
    <div>
      {formulas.map((formula, index) => (
        <div className={s.formula} key={`${formula.id}`}>
          <div className={s.column}>
            <Field label={t('predict.params.number-head-from')}>
              <Input
                defaultValue={formula.from}
                onChange={(_e, data) => {
                  if (data.value)
                    updateFormulas(index, { ...formula, from: data.value })
                }}
                appearance="filled-darker"
                className={s.input}
                type="number"
                min={0}
              />
            </Field>
          </div>

          <div className={s.column}>
            <Field label={t('predict.params.number-head-to')}>
              <Input
                defaultValue={formula.to}
                onChange={(_e, data) => {
                  if (data.value)
                    updateFormulas(index, { ...formula, to: data.value })
                }}
                appearance="filled-darker"
                className={s.input}
                type="number"
                min={0}
              />
            </Field>
          </div>

          <div className={s.column}>
            <Field label={t('predict.params.diameter')}>
              <Input
                defaultValue={formula.diameter}
                onChange={(_e, data) => {
                  if (data.value)
                    updateFormulas(index, { ...formula, diameter: data.value })
                }}
                appearance="filled-darker"
                className={s.input}
                type="number"
                min={0}
              />
            </Field>
          </div>
          <Button
            appearance="subtle"
            icon={<Dismiss16Regular />}
            size="small"
            onClick={() => {
              removeFormula(index)
            }}
          />
        </div>
      ),
      )}
    </div>
  )
}
