import { track } from 'tldraw'
import { usePage } from '../../../page/state/context'
import { t } from '../../../util/intl/t'
import { isPieceShape } from '../../piece/shape'
import { getSegmentMmSize, isSegmentShape } from '../../segment/shape'
import type { AnnotShape } from '../../shape/shape'

function getValue(shapes: AnnotShape[], options: {
  scale: number
}): string {
  const { scale } = options

  if (shapes.length === 0)
    throw new Error('Shapes must have at least 1 shape.')

  if (shapes.every(isPieceShape))
    return `${shapes.length.toFixed(0)} ${t('annot.total.piece-unit')}`

  if (shapes.every(isSegmentShape)) {
    const mm = shapes.reduce((prev, shape) => {
      return prev + getSegmentMmSize(shape, { scale })
    }, 0)
    return `${(mm / 1000).toFixed(1)} ${t('annot.total.length-unit')}`
  }

  throw new Error('All annotation shapes must be of the same type.')
}

export const AnnotOverviewTotal = track((props: {
  shapes: AnnotShape[]
}): JSX.Element => {
  const { shapes } = props
  const { scale } = usePage().page

  return <>{getValue(shapes, { scale })}</>
})
