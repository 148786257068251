import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { createPieceCircleAttr, createPieceCircleShape } from './create'
import { isPieceCircleShape } from './shape'

export const PIECE_CIRCLE_TOOL_ID = 'piece-circle'

// This tool use to create water source
export function createPieceCircleTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class PieceCircleShapeTool extends AnnotGeoTool {
    static override id = PIECE_CIRCLE_TOOL_ID

    override followTool = null

    override isPrev(shape: AnnotShape) {
      return isPieceCircleShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createPieceCircleAttr(),
      }))
    }

    override toAnnot: GeoToAnnot = (props) => {
      const { geo, prev } = props

      const geoBox = new Box(geo.x, geo.y, geo.props.w, geo.props.h)
      const center = geoBox.center

      const geoShape = createPieceCircleShape({
        box: Box.FromCenter(center, { x: 4, y: 4 }),
        id: geo.id,
        color: prev?.props.color ?? null,
        group: prev?.meta.group ?? null,
        interactive: 'ByManual',
      })

      return geoShape
    }
  }
}
