import { Body1, Button, Link, makeStyles, tokens } from '@fluentui/react-components'
import { Dismiss16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { t } from '../../util/intl/t'
import { LATEST_VERSION, useNotifyVersion } from './help'

const useStyles = makeStyles({
  banner: {
    borderRadius: tokens.borderRadiusMedium,
    width: 'auto',
    position: 'fixed',
    backgroundColor: tokens.colorPaletteDarkOrangeBackground3,
    minHeight: '32px',
    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingVerticalS}`,
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.spacingHorizontalXXXL,
    boxShadow: tokens.shadow16,
    alignItems: 'center',
    color: tokens.colorNeutralBackground1,
  },
  action: {
    display: 'flex',
    gap: tokens.spacingHorizontalXS,
    alignItems: 'center',
  },
  button: {
    'width': 'auto',
    'color': tokens.colorNeutralBackground1,
    ':hover': {
      color: tokens.colorNeutralBackground1Hover,
    },
    'maxWidth': '24px',
    'minWidth': '24px',
    'height': '24px',
  },
  link: {
    'width': 'auto',
    'fontSize': '14px',
    'color': tokens.colorNeutralBackground1,
    'height': '24px',
    'display': 'flex',
    'alignItems': 'center',
    'textDecoration': 'underline',
    ':hover': {
      color: tokens.colorNeutralBackground1Hover,
    },
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: tokens.spacingHorizontalXXS,
  },
})

export function AppNotifyVersion(): ReactElement | null {
  const s = useStyles()

  const { updateVersion, version } = useNotifyVersion()

  if (version.seen === 'true')
    return null

  const onClose = () => {
    updateVersion({ ...version, seen: 'true' })
  }

  return (
    <div className={s.banner}>
      <div className={s.message}>
        <Body1>
          {`${t('app.notify.new-version')} ${LATEST_VERSION}.`}
        </Body1>
        <Link
          className={s.link}
          href="https://docs.aisekisan.com"
          target="_blank"
          onClick={onClose}
        >
          {t('app.notify.learn-more')}
        </Link>
      </div>
      <div className={s.action}>
        <Button appearance="transparent" icon={<Dismiss16Regular />} onClick={onClose} className={s.button} />
      </div>
    </div>
  )
}
