import { makeStyles } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useSetting } from '../../../setting/setting'
import { MenuRadio } from '../../../ui/menu'
import type { Option } from '../../../ui/option'
import { ToolbarLabel } from '../../../ui/toolbar/label'
import { t } from '../../../util/intl/t'
import type { SegmentLabel } from './type'
import { segmentLabelSchema } from './type'

const useStyles = makeStyles({
  button: {
    width: '140px',
  },
})

export function SegmentLabelMenu(): ReactElement {
  const s = useStyles()
  const { setting, updateSetting } = useSetting()

  const OPTIONS: Option<SegmentLabel>[] = [
    { label: t('annot.segment.size'), value: 'size' },
    { label: t('annot.segment.shape'), value: 'shape' },
    { label: t('annot.segment.none'), value: 'none' },
  ]
  return (
    <>
      <ToolbarLabel>
        {t('annot.segment.label')}
      </ToolbarLabel>
      <MenuRadio
        value={setting.segment}
        setValue={segment => updateSetting({ segment })}
        displayFallback={null}
        //
        options={OPTIONS}
        parse={segmentLabelSchema.parse}
        button={{
          appearance: 'subtle',
          className: s.button,
        }}
      />
    </>
  )
}
