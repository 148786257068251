import { useEffect, useState } from 'react'
import type { Editor } from 'tldraw'
import { ApiError } from '../../../generated/server'
import { isAnnotShape } from '../../annot/shape/shape'
import { useAttrs } from '../../attr/state/context'
import { useInsulAttrs } from '../../insul/context'
import { savePageAnnots } from '../../page/save'
import { usePage } from '../../page/state/context'
import { useToast } from '../../ui/toast'

export function useEditorSyncShapes(props: { editor: Editor }): void {
  const { editor } = props

  const initialShapes = usePage().annots.shapes
  const [ready, setReady] = useState(false)
  const { attrs } = useAttrs()
  const { attrs: insuls } = useInsulAttrs()
  const { toast } = useToast()

  const currentShapes = editor.getCurrentPageShapes().filter(isAnnotShape)

  // @TODO: Use "store" so we can "seed" the shapes in before rendering
  // - https://canary.tldraw.dev/docs/persistence#The-store-prop
  useEffect(() => {
    if (ready === true)
      return

    editor.deleteShapes(editor.getCurrentPageShapes())
    editor.createShapes(initialShapes)
    editor.clearHistory()
    setReady(true)
  }, [editor, initialShapes, ready])

  useEffect(() => {
    if (ready === false)
      return

    const id = window.setTimeout(async () => {
      const save = await savePageAnnots({ shapes: currentShapes, attrs, insuls })
      if (save.status === 'failed') {
        if (save.error instanceof ApiError) {
          toast(save.error.message)
          throw new Error(save.error.message, { cause: save.error.body })
        }
        else {
          toast(save.error)
          throw new Error(save.error)
        }
      }
    }, 2000)

    return () => {
      window.clearTimeout(id)
    }
  }, [ready, currentShapes, attrs, insuls, toast])
}
