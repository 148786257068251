type AiskGlobal = {
  download_images: boolean
}

declare global {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface Window {
    __debug__: AiskGlobal
  }
}

window.__debug__ = window.__debug__ || { download_images: false }

export function downloadImage(file: Blob) {
  const element = document.createElement('a')
  element.href = URL.createObjectURL(file)
  element.download = 'debug.png'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
