import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from './animation'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '160px',
  },
  start: {
    width: '10px',
    height: '10px',
    backgroundColor: tokens.colorBrandForeground1,
    ...shorthands.border('2px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRadius('100%'),
    ...makeHelpAnimation([
      {
        states: {
          S: { transform: 'scale(1)' },
          D: { transform: 'scale(0)' },
        },
        keyframes: [
          [0, 'D'],
          [1, 'S'],
          [9, 'S'],
          [10, 'D'],
        ],
      },
    ]),
  },
  end: {
    width: '10px',
    height: '10px',
    backgroundColor: tokens.colorBrandForeground1,
    ...shorthands.border('2px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRadius('100%'),
    ...makeHelpAnimation([
      {
        states: {
          S: { transform: 'scale(1)' },
          D: { transform: 'scale(0)' },
        },
        keyframes: [
          [0, 'D'],
          [6, 'D'],
          [7, 'S'],
          [9, 'S'],
          [10, 'D'],
        ],
      },
    ]),
  },
})

export function HelpLineHandleStatic(): JSX.Element {
  const s = useStyles()

  return (
    <div className={s.container}>
      <div className={s.start} />
      <div className={s.end} />
    </div>
  )
}
