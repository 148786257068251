import type { Editor } from 'tldraw'
import { ApiError } from '../../../generated/server'
import { isPieceCircleShape } from '../../annot/piece/circle/shape'
import type { AttrRecord } from '../../attr/state/context'
import { t } from '../../util/intl/t'
import type { SetState } from '../../util/react/state'
import { isPromiseFulfilled, isPromiseRejected } from '../../util/web/promise'
import type { PredictAreaShape } from '../area/shape'
import { isPredictAreaShape } from '../area/shape'
import { isPredictGroupSampleShape } from '../group-sample/shape'
import { isValidCropSamples } from '../model/fire-head'
import type { PredictModel } from '../model/option'
import { isPredictSampleShape } from '../sample/shape'
import { getPredictFetchCropShapes, makeCropPredictFetch } from './crop'
import { mergePredictFetchOutput } from './merge'
import type { PredictFetchInput } from './type'

type Props = {
  page: string
  model: PredictModel | null
  editor: Editor
  attrs: AttrRecord
  setAttrs: SetState<AttrRecord>
  pdf: HTMLCanvasElement
}

type Result = {
  total: number
  successful: number
  failed: string[]
  aiPredictionRemaining: number
}

const IMAGE_SIZE_LIMIT: number = 33554432

export async function fetchPredictAll(props: Props): Promise<Result> {
  const { page, model, pdf, editor, attrs, setAttrs } = props

  // Mark the editor so users can undo the result but keep their input
  editor.mark()
  editor.selectNone()
  const allShapes = editor.getCurrentPageShapes()

  if (model === null)
    throw new Error(t('predict.fetch.no-model'))

  // Samples are used for all areas
  const predictSamples = allShapes.filter(isPredictSampleShape)
  const groupSamples = allShapes.filter(isPredictGroupSampleShape)

  const samples = [...predictSamples, ...groupSamples]

  if (model.sample && model.value === 'fire-head' && samples.length === 0)
    throw new Error(t('predict.fetch.no-sample'))
  editor.updateShapes(samples.map(s => ({ ...s, isLocked: false })))

  // Each area will lead to a separate predict request
  const areas = allShapes.filter(isPredictAreaShape)
  if (areas.length === 0)
    throw new Error(t('predict.fetch.no-area'))

  const crop = makeCropPredictFetch({ editor, pdf })

  const cropSamples = await Promise.all(samples.map(crop))
  const cropAreas = await Promise.all(areas.map(crop))

  if (!isValidCropSamples(cropSamples, cropAreas))
    throw new Error(t('predict.fetch.crop-samples.invalid'))

  // Water source is required for fire pipe & diameter
  if ((model.value === 'fire-pipe' || model.value === 'fire-pipe-diameter')
    && allShapes.filter(isPieceCircleShape).length === 0)
    throw new Error(t('predict.fetch.water-source.invalid'))

  if (model.value === 'fire-alarm') {
    const isValidGroup = groupSamples.every(groupSample => groupSample.meta.group_template !== null)
    if (!isValidGroup)
      throw new Error('Please review and select a valid group')

    const groupTemplates = groupSamples.reduce((acc: Record<string, number>, obj) => {
      const template = obj.meta && obj.meta.group_template
      if (template)
        acc[template] = (acc[template] || 0) + 1

      return acc
    }, {})

    if (Object.values(groupTemplates).some(template => template < 2))
      throw new Error(t('predict.fetch.sample-fire-alarm.invalid'))
  }

  const promises = areas.map(async (area) => {
    // Lock and area individually
    editor.updateShape({
      ...area,
      meta: { ...area.meta, busy: true },
      isLocked: true,
    } satisfies PredictAreaShape)

    const input: PredictFetchInput = {
      area: await crop(area),
      attrs,
      editor,
      page,
      samples: cropSamples,
      session: crypto.randomUUID(),
      shapes: getPredictFetchCropShapes({ editor, area }),
      aiParams: area.meta.params ? JSON.stringify(area.meta.params) : null,
    }

    if (input.area.blob.size > IMAGE_SIZE_LIMIT)
      throw new Error(t('predict.fetch.image-size-limit'))

    return model.fetch(input).finally(() => {
      // Unlock whether it's successful or not
      editor.updateShape({
        ...area,
        meta: { ...area.meta, busy: false },
        isLocked: false,
      } satisfies PredictAreaShape)
    }).then((output) => {
      model.cleanUp(input)
      // Delete the area if successful
      editor.deleteShape(area.id)
      return output
    })
  })

  const all = await Promise.allSettled(promises)
  const good = all.filter(isPromiseFulfilled).map(r => r.value)
  const bad = all.filter(isPromiseRejected).map(r => r.reason)

  // @TODO: Move clean up to model.cleanUp?
  // If so, we don't need to separate good and bad here.

  // Clean up
  editor.updateShapes(samples.map(s => ({ ...s, isLocked: false })))
  // Only delete samples if all areas are successful
  if (good.length === areas.length)
    editor.deleteShapes(samples.map(s => s.id))

  // Apply successful predictions
  const output = mergePredictFetchOutput(good)
  editor.createShapes(output.shapes)
  setAttrs(prev => ({ ...prev, ...output.attrs }))

  // Collect errors
  const failed: string[] = bad.map((error) => {
    if (error instanceof ApiError && error.status === 429)
      return t('predict.fetch.limit')
    if (error instanceof ApiError && error.status === 422)
      return t('predict.fetch.required-pdf-vector')
    return String(error)
  })

  return {
    total: all.length,
    failed,
    successful: good.length,
    aiPredictionRemaining: output.aiPredictionRemaining,
  }
}
