import { Select, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { useId, useRef } from 'react'
import { useEditor } from 'tldraw'
import { useEditorTheme } from '../../editor/util/theme'
import { EDITOR_ZOOM_VARIABLE } from '../../editor/util/zoom'
import { t } from '../../util/intl/t'
import type { PredictGroupSampleShape } from './shape'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--psc-fill'?: string
  }
}

const useStyles = makeStyles({
  container: {
    'width': `calc(0.2px * var(${EDITOR_ZOOM_VARIABLE}))`,
    'display': 'flex',
    '& svg path[fill]:not([fill=\'none\'])': {
      opacity: 0.5,
      // Use "solid" instead of the default "semi" colour.
      // See "area/component" for more details.
      fill: 'var(--psc-fill)',
    },
    '& svg path[stroke]:not([stroke=\'none\'])': {
      opacity: 0,
    },
  },
  label: {
    position: 'absolute',
    fontSize: '2px',
    top: 0,
    lineHeight: '14px',
  },
  group: {
    position: 'absolute',
    top: '110%',
  },
  select: {
    'left': 0,
    '::after': {
      backgroundImage: 'none',
    },
    '> select': {
      'border': 'none',
      'outlineColor': tokens.colorPaletteBlueBorderActive,
      'outlineWidth': '0.5px',
      'outlineStyle': 'solid',
      'height': '4px',
      'fontSize': '3px',
      'width': `calc(0.2px * var(${EDITOR_ZOOM_VARIABLE}))`,
      'minWidth': '8px',
      'paddingTop': 0,
      'paddingRight': 0,
      'paddingLeft': '1px',
      'paddingBottom': '1px',
      'lineHeight': 'normal',
      ':focus': {
        fontSize: '12px !important',
        outlineColor: tokens.colorPaletteBlueBorderActive,
        outlineWidth: '1px',
        outlineStyle: 'solid',
      },
    },
    '> span': {
      'width': '3px',
      'right': '0.5px',
      '> svg': {
        width: '3px',
      },
    },
  },
})

const GROUP_TEMPLATES = [
  {
    label: '1',
    value: crypto.randomUUID(),
  },
  {
    label: '2',
    value: crypto.randomUUID(),
  },
  {
    label: '3',
    value: crypto.randomUUID(),
  },
  {
    label: '4',
    value: crypto.randomUUID(),
  },
  {
    label: '5',
    value: crypto.randomUUID(),
  },
  {
    label: '6',
    value: crypto.randomUUID(),
  },
  {
    label: '7',
    value: crypto.randomUUID(),
  },
  {
    label: '8',
    value: crypto.randomUUID(),
  },
  {
    label: '9',
    value: crypto.randomUUID(),
  },
  {
    label: '10',
    value: crypto.randomUUID(),
  },
  {
    label: '11',
    value: crypto.randomUUID(),
  },
  {
    label: '12',
    value: crypto.randomUUID(),
  },
  {
    label: '13',
    value: crypto.randomUUID(),
  },
  {
    label: '14',
    value: crypto.randomUUID(),
  },
  {
    label: '15',
    value: crypto.randomUUID(),
  },
  {
    label: '16',
    value: crypto.randomUUID(),
  },
  {
    label: '17',
    value: crypto.randomUUID(),
  },
  {
    label: '18',
    value: crypto.randomUUID(),
  },
  {
    label: '19',
    value: crypto.randomUUID(),
  },
  {
    label: '20',
    value: crypto.randomUUID(),
  },
]
export function PredictGroupSampleComponent(props: {
  original: ReactNode
  shape: PredictGroupSampleShape
}): ReactElement {
  const { original, shape } = props

  const editor = useEditor()
  const s = useStyles()
  const theme = useEditorTheme()
  const selectId = useId()
  const ref = useRef<HTMLSelectElement>(null)

  return (
    <div
      className={s.container}
      style={{ '--psc-fill': theme.blue.solid }}
    >
      {original}
      <div className={s.group}>
        <label htmlFor={selectId} className={s.label}>{t('predict.group-sample.group')}</label>
        <Select
          ref={ref}
          id={selectId}
          className={s.select}
          defaultValue={shape.meta.group_template ?? ''}
          onChange={(event, data) => {
            event.preventDefault()
            ref.current?.blur()

            if (!data.value)
              return
            const group_template = data.value as string

            const next = { ...shape, meta: { ...shape.meta, group_template } }
            editor.updateShape(next)
          }}
        >
          <option value="">-</option>
          {GROUP_TEMPLATES.map(template => (
            <option
              key={template.value}
              value={template.value}
            >
              {template.label}
            </option>
          ),
          )}
        </Select>
      </div>
    </div>
  )
}
