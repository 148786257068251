import type { BoxLike, TLDefaultColorStyle, TLShapeId } from 'tldraw'
import { createShapeId } from 'tldraw'
import type { Interactive } from '../../../../generated/server'
import { ATTR_EQUIP_VALUES } from '../../../attr/field/equip/value'
import type { AttrValue } from '../../../attr/state/context'
import { ATTR_FIELDS_DEFAULT } from '../../../attr/state/context'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { PieceCircleShapePartial } from './shape'

export function createPieceCircleShape(props: {
  box: BoxLike
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
  interactive: Interactive | null
}): PieceCircleShapePartial {
  const { box, id, group, color, interactive } = props

  const { x, y, w, h } = box

  const piece: PieceCircleShapePartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    props: {
      geo: 'oval',
      dash: 'solid',
      fill: 'none',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w,
      h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      interactive,
      annot: 'piece',
      shape: 'circle',
    },
  }

  return piece
}

export function createPieceCircleAttr(): AttrValue {
  return {
    ...(ATTR_FIELDS_DEFAULT),
    equip: ATTR_EQUIP_VALUES.WATER_SOURCE,
  }
}
