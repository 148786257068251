import { useEffect } from 'react'
import type { Editor } from 'tldraw'
import { useSetting } from '../../setting/setting'

export function useEditorSyncTheme(props: { editor: Editor }): void {
  const { editor } = props

  const theme = useSetting().setting.theme
  useEffect(() => {
    editor.user.updateUserPreferences({ colorScheme: theme })
  }, [editor, theme])
}
