import { makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'

const useStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground3,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: tokens.spacingVerticalS,
    gap: tokens.spacingVerticalS,
  },
  header: {
    flex: '0 0 auto',
  },
  main: {
    flex: '1 1 0px',
    display: 'flex',
    minHeight: 0,
    gap: tokens.spacingVerticalS,
  },
  side: {
    flex: '0 0 auto',
    width: '240px',
  },
  center: {
    flex: '1 1 0px',
    minWidth: 0,
  },
})

export function AppFrame(props: {
  toolbar: ReactNode
  primary: ReactNode
  editor: ReactNode
  secondary: ReactNode
}): ReactElement {
  const { toolbar, editor, primary, secondary } = props
  const s = useStyles()

  return (
    <div className={s.container} draggable="false">
      <div className={s.header} draggable="false">
        {toolbar}
      </div>
      <div className={s.main} draggable="false">
        <div className={s.side} draggable="false">
          {primary}
        </div>
        <div className={s.center} draggable="false">
          {editor}
        </div>
        <div className={s.side} draggable="false">
          {secondary}
        </div>
      </div>
    </div>
  )
}
