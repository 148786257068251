import type { INTL_DICT_EN } from './en'

export const INTL_DICT_VI: typeof INTL_DICT_EN = {
  'annot.color.blue': 'Xanh dương',
  'annot.color.green': 'Xanh lá',
  'annot.color.lightBlue': 'Xanh nhạt',
  'annot.color.lightRed': 'Đỏ nhạt',
  'annot.color.lightViolet': 'Tím nhạt',
  'annot.color.orange': 'Cam',
  'annot.color.red': 'Đỏ',
  'annot.color.violet': 'Tím',
  'annot.color.yellow': 'Vàng',
  'annot.group.menu.hide-equipments': 'Ẩn các thiết bị khác',
  'annot.group.menu.hide': 'Ẩn các nhóm khác',
  'annot.group.menu.lock': 'Khóa các nhóm khác',
  'annot.panel.action.title': 'Hiển thị/Ẩn:',
  'annot.panel.all.show': 'Hiển thị tất cả',
  'annot.panel.all.unlock': 'Mở khóa tất cả',
  'annot.panel.empty': 'Chưa có thiết bị nào. Hãy nhấp vào “Tự động” để bắt đầu.',
  'annot.panel.selection.hide': 'Ẩn chú thích',
  'annot.panel.selection.lock': 'Khóa',
  'annot.piece.help-1': 'Bạn đang đếm số lượng của thiết bị.',
  'annot.piece.help-2': 'Để đếm thêm một thiết bị, nhấp chuột trái vào trên bản vẽ:',
  'annot.piece.help-3': 'Thiết bị sẽ được thêm vào nhóm đã chọn ở Bảng kết quả bên trái.',
  'annot.piece.help-4': 'Để bắt đầu đếm trong một nhóm mới, bỏ chọn tất cả các nhóm, sau đó nhấp chuột trái vào trên bản vẽ.',
  'annot.piece.help-5': 'Để chỉnh sửa thuộc tính của một thiết bị, chọn thiết bị đó và thay đổi các thuộc tính trên bảng Thuộc tính bên phải màn hình.',
  'annot.segment.fixed': 'Cố định',
  'annot.segment.help-1': 'Bạn đang bóc tách các đường ống.',
  'annot.segment.help-2': ' Để bắt đầu một đường ống, nhấp chuột trái vào điểm bắt đầu, sau đó nhấp vào điểm tiếp theo để tạo một đoạn. Bạn có thể tiếp tục thêm đoạn bằng cách nhấp vào các điểm khác. Để kết thúc, nhấp chuột phải hoặc nhấn phím Esc.',
  'annot.segment.help-3': 'Đoạn sẽ được thêm vào đường ống đã chọn ở bảng bên trái.',
  'annot.segment.help-4': 'Để chỉnh sửa một đường ống, chuyển sang công cụ Chọn, sau đó nhấp vào đường ống cần chỉnh sửa.',
  'annot.segment.label-size': 'Kích thước ống:',
  'annot.segment.label': 'Lựa chọn hiển thị:',
  'annot.segment.none': 'Không hiển thị',
  'annot.segment.scale': 'Tự điều chỉnh',
  'annot.segment.shape': 'Đường kính',
  'annot.segment.size': 'Chiều dài',
  'annot.sync.failed': 'Lưu kết quả thất bại',
  'annot.toolbar.cut': 'Cắt',
  'annot.toolbar.delete-none': 'Không thể xóa do không có gì được chọn',
  'annot.toolbar.delete': 'Xóa',
  'annot.toolbar.duplicate-none': 'Không có gì để sao chép',
  'annot.toolbar.duplicate': 'Sao chép',
  'annot.toolbar.extension': 'Ống chờ',
  'annot.toolbar.extract-multiple': 'Trích xuất',
  'annot.toolbar.extract-none': 'Không thể xuất do không có gì được chọn',
  'annot.toolbar.extract-single': 'Tách Nhóm',
  'annot.toolbar.head': 'Đầu phun',
  'annot.toolbar.piece': 'Thiết bị (cái)',
  'annot.toolbar.segment': 'Đường ống',
  'annot.toolbar.vertical-down': 'Ống đứng (xuống)',
  'annot.toolbar.vertical-up': 'Ống đứng (lên)',
  'annot.total.length-unit': 'm',
  'annot.total.piece-unit': 'cái',
  'annot.warning.length-body': 'Một số đo lường có thể không chính xác. Để thiết lập tỷ lệ, hãy vào tab "Bản vẽ".',
  'annot.warning.length-title': 'Tỷ lệ bản vẽ chưa được thiết lập.',
  'app.error.forbidden-desc.1': 'Bạn thiếu quyền truy cập vào trang này.',
  'app.error.forbidden-desc.2': 'Vui lòng liên hệ với chủ sở hữu của Tổ chức hoặc Dự án để gửi cho bạn một lời mời.',
  'app.error.help': 'Vui lòng làm mới trang hoặc liên hệ với chúng tôi',
  'app.error.limit': 'Số lượng tải xuống vượt quá hạn mức.',
  'app.error.title': 'Đã xảy ra lỗi khi tải trang',
  'app.error.unauthorized-desc.1': 'Bạn không được phép xem trang này.',
  'app.error.unauthorized-desc.2': 'Để tiếp tục, vui lòng đăng nhập lại.',
  'app.notify.hide': 'Ẩn',
  'app.notify.learn-more': 'Tìm hiểu thêm',
  'app.notify.new-version': 'Phiên bản mới:',
  'app.tab.annotate': 'Thủ công',
  'app.tab.attr': 'Thuộc tính',
  'app.tab.help': 'Hướng dẫn',
  'app.tab.list': 'Danh sách bản vẽ',
  'app.tab.page': 'Bản vẽ',
  'app.tab.predict': 'Tự động',
  'app.tab.result': 'Bảng kết quả',
  'app.tab.view': 'Xem',
  'app.theme.dark': 'Chế độ tối',
  'app.theme.light': 'Chế độ sáng',
  'app.toolbar.edit': 'Chỉnh sửa',
  'app.toolbar.help': 'Hướng dẫn',
  'attr.c-area.mixed': '(Nhiều lựa chọn)',
  'attr.c-module.mixed': '(Nhiều lựa chọn)',
  'attr.color.mixed': '(Nhiều lựa chọn)',
  'attr.equip.help': 'Các thay đổi được áp dụng cho tất cả các thiết bị trong cùng một nhóm. Để chỉ thay đổi một chú thích cụ thể, trước tiên hãy giải nén nó.',
  'attr.equip.mixed': '(Nhiều lựa chọn)',
  'attr.field.c-area': 'Khu vực thi công',
  'attr.field.c-module': 'Mô-đun',
  'attr.field.color': 'Màu sắc',
  'attr.field.equip': 'Thiết bị',
  'attr.field.material-1': 'Loại vật liệu',
  'attr.field.material-2': 'Tên vật liệu',
  'attr.field.pipe-type': 'Loại đường ống',
  'attr.field.thickness': 'Độ dày',
  'attr.field.type': 'Loại',
  'attr.insul-thickness.mixed': '(Nhiều lựa chọn)',
  'attr.material-1.mixed': '(Nhiều lựa chọn)',
  'attr.material-2.mixed': '(Nhiều lựa chọn)',
  'attr.panel.empty': 'Chọn các chú thích để xem thuộc tính của chúng ở đây.',
  'attr.panel.help': 'Các thay đổi cũng được áp dụng cho các thiết bị khác trong cùng nhóm với các thiết bị đã chọn. Để chỉ áp dụng các thay đổi cho các thiết bị đã chọn, hãy sử dụng "Trích xuất" trên thanh công cụ.',
  'attr.pipe-type.main': 'Chính',
  'attr.pipe-type.mixed': '(Nhiều lựa chọn)',
  'attr.pipe-type.sub': 'Phụ',
  'attr.shape.box': ' (Chiều rộng / chiều cao / chiều dài)',
  'attr.shape.circle': ' (Đường kính)',
  'attr.shape.custom': ' (Tùy chỉnh)',
  'attr.shape.electric': ' (Kilowatt)',
  'attr.shape.insulation': ' (Độ dày)',
  'attr.shape.label': 'Kích thước',
  'attr.shape.mixed': '(Nhiều lựa chọn)',
  'attr.shape.rectangle': ' (Chiều rộng / chiều dài)',
  'attr.shape.refrigerant': ' (size1 / size2)',
  'attr.type.FD': 'FD',
  'attr.type.mixed': '(Nhiều lựa chọn)',
  'attr.type.NonFD': 'Không FD',
  'editor.history.redo-none': 'Không có hành động nào sau đó',
  'editor.history.redo': 'Tái hiện',
  'editor.history.undo-none': 'Không còn hành động nào để hoàn tác',
  'editor.history.undo': 'Hoàn tác',
  'editor.level.error-exact': 'Đã ở 100%',
  'editor.level.error-max': 'Đã ở mức thu phóng tối đa',
  'editor.level.error-min': 'Đã ở mức thu phóng tối thiểu',
  'editor.level.in': 'Phóng to',
  'editor.level.out': 'Thu nhỏ',
  'editor.level.reset': '100%',
  'editor.navigate.hand': 'Di chuyển khung hình',
  'editor.navigate.select': 'Chọn',
  'editor.navigate.zoom': 'Thu phóng',
  'editor.target.error-empty': 'Chưa có thiết bị nào',
  'editor.target.error-none': 'Không có gì được chọn',
  'editor.target.page': 'Trang',
  'editor.target.selection': 'Lựa chọn',
  'editor.target.shapes': 'Tất cả các thiết bị',
  'editor.target.title': 'Thu phóng đến',
  'flag': '🇺🇸',
  'help.panel.hide': 'Ẩn hướng dẫn',
  'help.panel.show': 'Hiển thị hướng dẫn',
  'insul.field.material-1': 'Vật liệu',
  'insul.field.material-2': 'Bỏ bọc',
  'insul.header.auto-help': 'Tự động chọn vật liệu cách nhiệt: Khi các thuộc tính của thiết bị được chọn, hệ thống sẽ tự động xác định xem có cần thiết có cách nhiệt hay không và lựa chọn loại vật liệu cần thiết .',
  'insul.header.auto-title': 'Tự động',
  'insul.header.enabled': 'Có cách nhiệt',
  'insul.header.title': 'Vật liệu cách nhiệt',
  'insul.material-1.mixed': '(Nhiều lựa chọn)',
  'insul.material-2.mixed': '(Nhiều lựa chọn)',
  'page.field.category': 'Hạng mục',
  'page.field.construction': 'Dự án',
  'page.field.name': 'Trang',
  'page.field.predict-limit': 'Giới hạn bóc tách tự động',
  'page.field.view-limit': 'Giới hạn xem',
  'page.info.prefix': 'Hạng mục: ',
  'page.limit.remaining': ' lượt',
  'page.print.button': 'In bản vẽ',
  'page.print.in-progress': 'Đang tiến hành in',
  'paper.scale.cancel': 'Hủy',
  'paper.scale.help-1': 'Để đảm bảo tài liệu của bạn được thay đổi kích thước chính xác khi thay đổi kích thước giấy (ví dụ, từ A3 sang A1), tỷ lệ được thiết lập để điều chỉnh tự động (như từ 1:100 đến 1:50). Điều này nên hoạt động tốt với hầu hết các tệp PDF.',
  'paper.scale.help-2': 'Trong những trường hợp hiếm hoi khi tệp PDF có kích thước không chính xác, bạn có thể điều chỉnh tỷ lệ theo cách thủ công dưới đây:',
  'paper.scale.paper': 'Kích thước giấy:',
  'paper.scale.scale': 'Tỷ lệ là:',
  'paper.scale.submit': 'Cập nhật',
  'paper.scale.title': 'Điều chỉnh giấy và tỷ lệ',
  'predict.area.select': 'Chọn phạm vi',
  'predict.button.add-water-source': 'Điểm nguồn nước',
  'predict.fetch.action': 'Bắt đầu AI',
  'predict.fetch.crop-samples.invalid': 'Vui lòng kiểm tra tất cả mẫu và phạm vi đã chọn để bắt đầu với AI. Đảm bảo rằng kích thước mỗi mẫu nhỏ hơn phạm vi đã chọn.',
  'predict.fetch.image-size-limit': 'Phạm vi đã chọn quá lớn. Vui lòng chọn một phạm vi nhỏ hơn để bắt đầu với AI.',
  'predict.fetch.limit': 'Số lượng yêu cầu gửi tới AI vượt quá hạn mức cho phép. Vui lòng sao chép trang và thử lại trên trang mới.',
  'predict.fetch.no-area': 'Vui lòng chọn một khu vực',
  'predict.fetch.no-model': 'Vui lòng chọn một mô hình AI',
  'predict.fetch.no-sample': 'Vui lòng chọn ít nhất 1 mẫu',
  'predict.fetch.pending': 'AI đang hoạt động',
  'predict.fetch.required-pdf-vector': 'Khu vực đã chọn không chứa thông tin cần thiết để phát hiện ống tự động. Vui lòng tải lên tệp PDF được xuất từ các công cụ CAD rồi thử lại.',
  'predict.fetch.sample-fire-alarm.invalid': 'Vui lòng chọn ít nhất 2 mẫu đầu cảm biến để bắt đầu với AI',
  'predict.fetch.success': 'Hoàn thành',
  'predict.fetch.water-source.invalid': 'Vui lòng thêm ít nhất 1 nguồn nước và đảm bảo rằng nó thuộc phạm vi đã chọn trước khi bắt đầu với AI.',
  'predict.fire-area-help.1': 'Bạn đang yêu cầu AI hỗ trợ bóc tách.',
  'predict.fire-area-help.2': 'Bước 2: Kéo thả chuột trên bản vẽ để chọn một khu vực cần AI bóc tách:',
  'predict.fire-area-help.3': 'Bạn có thể chọn nhiều khu vực.',
  'predict.fire-area-help.4': 'Khi bạn đã sẵn sàng, nhấp vào Bắt đầu AI. Tùy thuộc vào kích thước và độ phức tạp của các khu vực, quá trình này có thể mất từ 10 giây đến 2 phút.',
  'predict.fire-help.1': 'Bạn đang yêu cầu AI hỗ trợ chú thích.',
  'predict.fire-help.2': 'Bước 1: Kéo thả chuột trên khung vẽ để chọn một mẫu để chú thích. Khu vực được chọn nên gần với đối tượng nhất có thể để đảm bảo lấy mẫu chính xác.',
  'predict.fire-help.3': 'Khi bạn đã sẵn sàng, nhấp vào Chọn khu vực để tiếp tục bước tiếp theo.',
  'predict.fire-pipe-help.1': 'Bạn đang yêu cầu AI hỗ trợ bóc tách tự động.',
  'predict.fire-pipe-help.2': 'Đầu tiên, bạn có thể cần phải kiểm tra lại các kết quả từ AI, thêm hoặc xóa nếu cần.',
  'predict.fire-pipe-help.3': 'Để thêm một thiết bị, nhấp chuột trái vào một điểm trên bản vẽ:',
  'predict.fire-pipe-help.4': 'Thiết bị sẽ được thêm vào Bảng kết quả bên trái.',
  'predict.fire-pipe-help.5': 'Tiếp theo, kéo thả chuột trên khung vẽ để chọn một khu vực cần AI bóc tách:',
  'predict.fire-pipe-help.6': 'Bạn có thể chọn nhiều khu vực.',
  'predict.fire-pipe-help.7': 'Khi bạn đã sẵn sàng, nhấp vào Bắt đầu AI. Tùy thuộc vào kích thước và độ phức tạp của các khu vực,quá trình này có thể mất từ 10 giây đến 2 phút.',
  'predict.fire-size-help.1': 'Bạn đang yêu cầu AI hỗ trợ bóc tách.',
  'predict.fire-size-help.2': 'Đầu tiên, bạn có thể cần phải kiểm tra lại các kết quả từ AI, thêm hoặc xóa nếu cần.',
  'predict.fire-size-help.3': 'Để thêm một đoạn ống, click chuột trái để chọn 2 điểm đầu cuối của đoạn ống. Chọn liên tiếp nhiều điểm để vẽ liên tiếp nhiều đoạn ống:',
  'predict.fire-size-help.4': 'Chọn một nhóm ở Bảng Kết quả để các đoạn ống được thêm vào nhóm này.',
  'predict.fire-size-help.5': 'Tiếp theo, kéo thả chuột trên bản vẽ để chọn khu vực cần AI bóc tách:',
  'predict.fire-size-help.6': 'Bạn có thể chọn nhiều khu vực.',
  'predict.fire-size-help.7': 'Khi bạn đã sẵn sàng, nhấp vào Bắt đầu AI. Tùy thuộc vào kích thước và độ phức tạp của các khu vực, quá trình này có thể mất từ 10 giây đến 2 phút.',
  'predict.group-sample.group': 'Nhóm',
  'predict.help.1': 'Bạn đang yêu cầu AI hỗ trợ bóc tách tự động.',
  'predict.help.2': 'Đầu tiên, kéo thả chuột trên khung vẽ để chọn một khu vực cần bóc tách:',
  'predict.help.3': 'Bạn có thể chọn nhiều khu vực.',
  'predict.help.4': 'Khi bạn đã sẵn sàng, nhấp vào Bắt đầu AI. Tùy thuộc vào kích thước và độ phức tạp của các khu vực, quá trình bóc tách có thể mất từ 10 giây đến 2 phút.',
  'predict.model.action': 'Mô hình AI',
  'predict.model.drain': 'Đường ống môi chất lạnh',
  'predict.model.duct': 'HVAC',
  'predict.model.fire-alarm': 'Đầu cảm biến',
  'predict.model.fire-head': 'Đầu phun',
  'predict.model.fire-pipe-diameter': 'Đường kính đường ống',
  'predict.model.fire-pipe': 'Đường ống',
  'predict.model.sanitary': 'Đường ống vệ sinh',
  'predict.params.add-formula': 'Thêm công thức',
  'predict.params.add-param': 'Thêm cách tính',
  'predict.params.apply': 'Áp dụng',
  'predict.params.cancel-button': 'Huỷ',
  'predict.params.custom-rule': 'Tuỳ chỉnh cách tính',
  'predict.params.custom': 'Tuỳ chỉnh',
  'predict.params.delete-param': 'Xoá công thức',
  'predict.params.diameter': 'Đường kính',
  'predict.params.equip': 'Thiết bị',
  'predict.params.exchange-value': 'Quy đổi',
  'predict.params.formulas': 'Công thức',
  'predict.params.global-rules': 'Quy tắc có sẵn',
  'predict.params.hydrant-equip': 'Đầu phun',
  'predict.params.next-button': 'Tiếp tục',
  'predict.params.number-head-from': 'Số đầu phun tối thiểu',
  'predict.params.number-head-to': 'Số đầu phun tối đa',
  'predict.params.number-of-heads': 'Số lượng đầu phun',
  'predict.params.params': 'Thông số',
  'predict.params.rule-detail': 'Chi tiết quy tắc',
  'predict.params.rule': 'Quy tắc',
  'predict.params.select-hint-1': 'Bạn có thể bỏ qua phần cài đặt này, hệ thống sẽ sử dụng quy tắc mặc định để tính toán kích thước đường ống.',
  'predict.params.select-hint-2': 'Quy tắc có thể được tuỳ chỉnh và áp dụng cho từng phạm vi riêng biệt bằng cách sửa đổi theo nhu cầu của bạn.',
  'predict.pricing-plan.agree': 'Đồng ý',
  'predict.pricing-plan.close': 'Đóng',
  'predict.pricing-plan.contact-owner': 'Số lượng yêu cầu dự đoán AI vượt quá giới hạn. Để tiếp tục sử dụng tính năng này, vui lòng liên hệ với chủ sở hữu của tổ chức để mua thêm các khối sử dụng.',
  'predict.pricing-plan.exchange-page-successful': 'Một trang đã được trừ khỏi hồ trang. Bạn có thể tiếp tục với yêu cầu của mình, vui lòng sử dụng nút "Bắt đầu AI" để tiếp tục.',
  'predict.pricing-plan.purchase-overage': 'Số lượng yêu cầu dự đoán AI vượt quá giới hạn. Để tiếp tục sử dụng tính năng này, một trang từ giới hạn trang vượt mức sẽ được mua.',
  'predict.pricing-plan.reached-limit': 'Đã đạt giới hạn dự đoán',
  'predict.pricing-plan.subtract-page': 'Số lượng yêu cầu dự đoán AI vượt quá giới hạn. Để tiếp tục sử dụng tính năng này, bạn cần trừ 1 trang khỏi hồ trang của mình. Bạn có đồng ý tiếp tục không?',
  'predict.pricing-plan.use-next-month': 'Số lượng yêu cầu dự đoán AI vượt quá giới hạn. Bạn không thể thực hiện thêm yêu cầu nào trên trang này. Vui lòng đợi đến tháng sau để tiếp tục.',
  'predict.sample.select': 'Chọn mẫu…',
  'predict.system.fire': 'HT PCCC',
  'predict.system.mechanical': 'HT Điều hòa thông gió',
  'predict.system.plumbing': 'HT Cấp thoát nước',
  'predict.water-source.help-1': 'Tiếp theo, bạn cần thêm nguồn nước.',
  'predict.water-source.help-2': 'Để thêm một nguồn nước, nhấp chuột trái vào một điểm trên bản vẽ:',
  'predict.water-source.help-3': 'Khi bạn đã sẵn sàng, nhấp vào "Chọn phạm vi" để tiến hành bước tiếp theo.',
  'scale.error.none': 'Không thể thiết lập tỷ lệ: Không có khoảng cách được xác định',
  'scale.error.number': 'Không thể thiết lập tỷ lệ: Chiều dài không xác định',
  'scale.help.1': 'Bạn đang thiết lập tỷ lệ bản vẽ bằng cách đo đạc khoảng cách của một kích thước có trên bản vẽ.',
  'scale.help.2': 'Đầu tiên, kéo thả chuột trên bản vẽ để xác định một khoảng cách:',
  'scale.help.3': 'Thứ hai, nhập kích thước thực tế vào ô “khoảng cách” trên thanh công cụ. Đơn vị tính bằng milimet.',
  'scale.help.4': 'Cuối cùng, nhấn "Enter" để hệ thống tính toán tỷ lệ.',
  'scale.idle.title': 'Tỷ lệ:',
  'scale.measure.length': 'Khoảng cách (mm):',
  'scale.menu.adjust': 'Chi tiết…',
  'scale.menu.measure': 'Thiết lập…',
  'scale.menu.not-set': 'Chưa thiết lập',
  'segment.geo.length': 'Chiều dài (m):',
}
// Do not use "as const" here,
// as we will use this to enforce the type of the "vi" dict.
