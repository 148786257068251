import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AppError } from './app/error.tsx'
import { AppPending } from './app/pending.tsx'
import { AppReady } from './app/ready.tsx'
import { AttrProvider } from './attr/state/provider.tsx'
import { EditorProvider } from './editor/provider/provider.tsx'
import { ErrorBoundary } from './error/boundary.tsx'
import { initError } from './error/init.ts'
import { InsulAttrProvider } from './insul/provider.tsx'
import './main.css'
import { PageProvider } from './page/provider/provider.tsx'
import { WidgetProvider } from './ui/provider.tsx'
import { ServerProvider } from './util/data/provider.tsx'
import { getStrict } from './util/web/primitive.ts'

initError()

const container = getStrict(document.getElementById('root'))
const root = createRoot(container)

root.render(
  <StrictMode>
    <ErrorBoundary>
      <ServerProvider>
        <WidgetProvider>
          <PageProvider Pending={AppPending} Error={AppError}>
            <AttrProvider>
              <InsulAttrProvider>
                <EditorProvider>
                  <AppReady />
                </EditorProvider>
              </InsulAttrProvider>
            </AttrProvider>
          </PageProvider>
        </WidgetProvider>
      </ServerProvider>
    </ErrorBoundary>
  </StrictMode>,
)
