import { makeStyles, tokens } from '@fluentui/react-components'
import { CalendarPattern16Filled, WeatherPartlyCloudyDay16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useRef } from 'react'
import type { AnnotShape } from '../annot/shape/shape'
import { getAutoInsul } from '../insul/auto'
import { useInsulAttrs } from '../insul/context'
import { InsulAttrPanel } from '../insul/panel'
import type { InsulAttrValue } from '../insul/value'
import { INSUL_ATTR_DEFAULT } from '../insul/value'
import { useSetting } from '../setting/setting'
import type { AttrSelection, AttrSelectionValue } from '../util/attr/selection'
import { createAttrSelection, getAttrSelectionValue } from '../util/attr/selection'
import { useAttrTreeSingle } from '../util/attr/tree/data'
import { useAttrTreeDropdownValue } from '../util/attr/tree/dropdown'
import { AttrTreeField } from '../util/attr/tree/field'
import { t } from '../util/intl/t'
import { getStrict } from '../util/web/primitive'
import { AttrCModuleField } from './field/c-module/field'
import { AttrEquipField } from './field/equip/field'
import { ATTR_EQUIP_VALUES, isFirePieceEquip } from './field/equip/value'
import { AttrShapeField } from './field/shape/field'
import { AttrTypeField } from './field/type/field'
import type { AttrValue } from './state/context'
import { useAttrs } from './state/context'

const useStyles = makeStyles({
  divider: {
    height: tokens.spacingVerticalL,
    flexShrink: 0,
  },
})

export function AttrForm(props: {
  attrs: AttrValue[]
  shapes: AnnotShape[]
  updateShapes: (update: Partial<AttrValue>, shapes: AnnotShape[]) => void
}): ReactElement | null {
  const { attrs, shapes, updateShapes } = props

  const all = useAttrs()
  const allInsul = useInsulAttrs()
  const s = useStyles()

  const autoInsulSetting = useSetting().setting.autoInsul
  /**
   * This ref is used to update insulation attributes automatically,
   * following changes from the main attributes.
   *
   * The tricky part here is that insulation update is calculated during render,
   * so if we update it in the main attributes' "set",
   * we are actually using the old values instead of the new one in "update".
   *
   * The current workaround is to call "setInsul" in the next event loop,
   * where the new values are already updated and stored in this ref.
   */
  const autoInsulRef = useRef<Partial<InsulAttrValue>>({})

  const create = <Value extends AttrSelectionValue>(
    getter: (prev: AttrValue) => Value,
  ): AttrSelection<Value> => {
    return createAttrSelection(attrs.map(getter))
  }

  const get = getAttrSelectionValue

  const equip = create(a => a.equip)
  const equipValue = get(equip, -1)
  const type = create(a => a.type)

  const material1 = create(a => a.material1)
  const material1Value = useAttrTreeDropdownValue({
    mixedText: t('attr.material-1.mixed'),
    value: material1,
    tree: { equip: equipValue, type: 'material', node: 0 },
  })

  const material2 = create(a => a.material2)
  const material2Value = useAttrTreeDropdownValue({
    mixedText: t('attr.material-2.mixed'),
    value: material2,
    tree: { equip: equipValue, type: 'material', node: get(material1, -1) },
  })

  const cArea = create(a => a.cArea)
  const cAreaValue = useAttrTreeDropdownValue({
    mixedText: t('attr.c-area.mixed'),
    value: cArea,
    tree: { equip: equipValue, type: 'construction_area', node: 0 },
  })

  const annotType = createAttrSelection(shapes.map(s => s.meta.annot))
  const insulTree = useAttrTreeSingle({ equip: ATTR_EQUIP_VALUES.INSULATION, type: 'material' })

  const setInsul = (update: Partial<InsulAttrValue>): void => {
    const nextAll = shapes.reduce((acc, shape) => {
      const attr = allInsul.attrs[shape.meta.group] ?? INSUL_ATTR_DEFAULT
      acc[shape.meta.group] = { ...attr, ...update }
      return acc
    }, { ...allInsul.attrs })
    allInsul.setAttrs(nextAll)
  }

  const set = (update: Partial<AttrValue>): void => {
    const nextAll = shapes.reduce((acc, shape) => {
      // Technically, we could use "attrs" instead of "shapes", and always have
      // a defined "attr". However, using "shapes" here is more reliable because
      // "attrs" may not contain some groups, which is expected during the
      // rendering but should be an error in user actions like here.
      const attr = getStrict(all.attrs[shape.meta.group])
      acc[shape.meta.group] = { ...attr, ...update }
      return acc
    }, { ...all.attrs })
    all.setAttrs(nextAll)
    updateShapes(update, shapes)

    if (autoInsulSetting && !isFirePieceEquip(equipValue)) {
      // We actually don't have the updated values at this point,
      // so we need to "queue" the update to the next loop,
      // when we should have the updated values,
      // as they are calculated during render.
      window.setTimeout(() => {
        setInsul(autoInsulRef.current)
      }, 0)
    }
  }

  // Keep the ref always updated. This does not have practical side effects.
  // The actual update action happens in "set" above.
  autoInsulRef.current = getAutoInsul({
    tree: insulTree,
    equip: get(equip, 1),
    type: get(type, ''),
    material1: material1Value,
    material2: material2Value,
    cArea: cAreaValue,
  })

  if (get(equip, -1) === ATTR_EQUIP_VALUES.WATER_SOURCE)
    return null

  return (
    <>
      <AttrEquipField
        annotType={get(annotType, null)}
        equip={equip}
        setEquip={equip => set({ equip })}
      />
      {material1Value && (
        <AttrTreeField
          value={material1Value}
          setValue={material1 => set({ material1 })}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('attr.field.material-1')}
        />
      )}
      {material2Value && (
        <AttrTreeField
          value={material2Value}
          setValue={material2 => set({ material2 })}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('attr.field.material-2')}
        />
      )}
      <AttrShapeField
        equip={equipValue}
        nodes={[0, get(material1, -1), get(material2, -1)]}
        shape={create(a => a.shape)}
        setShape={shape => set({ shape })}
      />
      <AttrTypeField
        equip={equipValue}
        type={type}
        setType={type => set({ type })}
      />
      {/* @TODO: Add comment on why this check is outside */}
      {cAreaValue && (
        <AttrTreeField
          value={cAreaValue}
          setValue={cArea => set({ cArea })}
          icon={icon => <WeatherPartlyCloudyDay16Filled className={icon.className} />}
          label={t('attr.field.c-area')}
        />
      )}
      <AttrCModuleField
        cModule={create(a => a.cModule)}
        setCModule={cModule => set({ cModule })}
      />
      <div className={s.divider} />
      {!isFirePieceEquip(equipValue) && (
        <InsulAttrPanel
          shapes={shapes}
          set={setInsul}
        />
      )}
    </>
  )
}
