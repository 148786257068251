import { Box, DefaultColorStyle, shapeIdValidator } from 'tldraw'
import type { AnnotationData, PipeLineSegment, PipeLineSegmentVertical } from '../../../util/data/server'
import { createSegmentVerticalShape } from './create'
import type { SegmentVerticalPartial, SegmentVerticalShape } from './shape'

export function toServerSegmentVertical(
  client: SegmentVerticalShape,
): PipeLineSegment {
  const box = new Box(client.x, client.y, client.props.w, client.props.h)

  const vertical: PipeLineSegmentVertical = {
    length: client.meta.mm,
    point: { x: box.center.x, y: box.center.y },
    direction: client.meta.direction,
  }

  const server: PipeLineSegment = {
    type: 'Vertical',
    uuid: client.id,
    inner: vertical,
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
  }

  return server
}

export function fromServerSegmentVertical(props: {
  server: PipeLineSegmentVertical
  segment: PipeLineSegment
  group: AnnotationData
}): SegmentVerticalPartial {
  const { group, server, segment } = props

  const vertical = createSegmentVerticalShape({
    center: server.point,
    direction: server.direction,
    mm: server.length,
    id: shapeIdValidator.validate(segment.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    interactive: segment.interactive ?? null,
  })

  return vertical
}
