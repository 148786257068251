import { Card, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { ToolbarSpace } from '../../ui/toolbar/space'
import { IntlLocaleMenu } from '../../util/intl/locale/menu'
import { AppToolbarContent } from './content'
import { AppToolbarHelp } from './help'
import { AppToolbarLogo } from './logo'
import { AppToolbarMenu } from './menu'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalXS,
  },
  header: {
    flex: '0 0 auto',
    display: 'flex',
    gap: tokens.spacingHorizontalS,
  },
  content: {
    flex: '0 0 auto',
    overflow: 'auto',
  },
})
//

export function AppToolbar(): ReactElement {
  const s = useStyles()

  return (
    <div className={s.box} draggable={false}>
      <div className={s.header}>
        <AppToolbarLogo />
        <AppToolbarMenu />
        <ToolbarSpace />
        <IntlLocaleMenu />
        <AppToolbarHelp />
      </div>
      <Card className={s.content} size="small" appearance="filled">
        <AppToolbarContent />
      </Card>
    </div>
  )
}
