import { Input, Select, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Toolbox16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { track, useEditor } from 'tldraw'
import type { AIModelParamsDetail } from '../../../generated/server'
import { useAttrEquipOptions } from '../../attr/field/equip/value'
import { Field } from '../../ui/field'
import { locale } from '../../util/intl/locale/type'
import { t } from '../../util/intl/t'
import type { PredictAreaShape } from '../area/shape'
import { isPredictAreaShape } from '../area/shape'
import { PredictParamRuleForm } from './rule'
import type { AIParams } from './type'
import { parseAIModelParamsServer, toAIModelParamsServer } from './type'

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    margin: `${tokens.spacingHorizontalM} 0 0 0`,
  },

  gapM: {
    rowGap: tokens.spacingHorizontalM,
  },
  select: {
    '> select': {
      minWidth: 'auto',
      paddingRight: 0,
    },
  },
  marginBottom: {
    marginBottom: `${tokens.spacingHorizontalM}`,
  },
})

export const PredictParamForm = track((props: {
  defaultValues: AIModelParamsDetail
  area: PredictAreaShape
}): ReactElement | null => {
  const { defaultValues, area } = props

  const s = useStyles()
  const editor = useEditor()

  const values = parseAIModelParamsServer(defaultValues)

  const formValues = area && isPredictAreaShape(area) && area.meta.params ? parseAIModelParamsServer({ params: area.meta.params }) : values

  if (area.meta.params === null) {
    // The meta area shape should be updated since it is calculated during rendering.
    window.setTimeout(() => {
      editor.updateShape({
        ...area,
        meta: { ...area.meta, ...toAIModelParamsServer(formValues) },
      })
    }, 0)
  }

  const form = useForm<AIParams>({ defaultValues: formValues })

  const language = locale === 'vi' ? 'ja' : locale

  const equipmentClasses = useAttrEquipOptions().filter(equip => equip.locale === language)

  const { getValues, setValue } = form

  const updateValues = (aiParams: AIParams): void => {
    editor.updateShape({
      ...area,
      meta: { ...area.meta, ...toAIModelParamsServer(aiParams) },
    })
    editor.mark()
  }

  const params = form.getValues()
  return (
    <div>
      <div className={mergeClasses(s.gapM, s.column, s.marginBottom)}>
        <Field label={t('predict.params.hydrant-equip')} icon={icon => <Toolbox16Filled className={icon.className} />}>
          <Select
            name="hydrant-equipment"
            appearance="filled-darker"
            value={getValues('hydrantRule.equipmentClass').toString()}
            onChange={(_e, data) => {
              if (data.value) {
                setValue('hydrantRule.equipmentClass', data.value)
                updateValues({
                  ...params,
                  hydrantRule: {
                    ...params.hydrantRule,
                    equipmentClass: data.value,
                  },
                })
              }
            }}
            className={s.select}
          >
            {equipmentClasses.map((equip, _index) => (
              <option key={`${equip.equipmentClass}-locale`} value={equip.equipmentClass.toString()}>
                {equip.localed}
              </option>
            ))}
          </Select>
        </Field>

        <Field label={t('predict.params.exchange-value')}>
          <Input
            defaultValue={getValues('hydrantRule.exchangeValue').toString() ?? ''}
            onChange={(_e, data) => {
              if (data.value) {
                setValue('hydrantRule.exchangeValue', data.value)
                updateValues({
                  ...params,
                  hydrantRule: {
                    ...params.hydrantRule,
                    exchangeValue: data.value,
                  },
                })
              }
            }}
            appearance="filled-darker"
            name="hydrant_rule.exchange_value"
          />
        </Field>
        <PredictParamRuleForm form={form} equipmentClasses={equipmentClasses} updateAreaShape={updateValues} />
      </div>
    </div>
  )
})
