import type { TldrawEditorBaseProps } from 'tldraw'
import { HandTool, SelectTool, ZoomTool } from 'tldraw'
import { createAnnotCutTool } from '../../annot/cut/tool'
import { createPieceBoxTool } from '../../annot/piece/box/tool'
import { createPieceCheckTool } from '../../annot/piece/check/tool'
import { createPieceCircleTool } from '../../annot/piece/circle/tool'
import { createSegmentExtensionTool } from '../../annot/segment/extension/tool'
import { BaseLineTool } from '../../annot/segment/flat/line'
import { createSegmentFlatTool } from '../../annot/segment/flat/tool'
import { createSegmentVerticalTool } from '../../annot/segment/vertical/tool'
import type { AttrRecord } from '../../attr/state/context'
import { ScaleTool } from '../../page/scale/tool'
import { PredictAreaTool } from '../../predict/area/tool'
import { PredictGroupSampleTool } from '../../predict/group-sample/tool'
import { PredictSampleTool } from '../../predict/sample/tool'
import type { SetState } from '../../util/react/state'

export function createEditorTools(props: {
  setAttrs: SetState<AttrRecord>
}): TldrawEditorBaseProps['tools'] {
  const { setAttrs } = props

  return [
    HandTool,
    ZoomTool,
    SelectTool,
    //
    ScaleTool,
    PredictAreaTool,
    PredictSampleTool,
    PredictGroupSampleTool,
    //
    BaseLineTool,
    //
    createPieceCheckTool({ setAttrs }),
    createPieceBoxTool({ setAttrs }),
    createPieceCircleTool({ setAttrs }),
    //
    createSegmentFlatTool({ setAttrs }),
    createSegmentVerticalTool({ setAttrs, direction: 'down' }),
    createSegmentVerticalTool({ setAttrs, direction: 'up' }),
    createSegmentExtensionTool({ setAttrs }),
    //
    createAnnotCutTool({ setAttrs }),
  ]
}
