import { Checkbox, makeStyles } from '@fluentui/react-components'
import type { Editor } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { getArraysDifferent, getArraysIntersect, getArraysUnion } from '../../../util/web/array'
import type { AnnotShape } from '../../shape/shape'
import { isAnnotShape } from '../../shape/shape'

const useStyles = makeStyles({
  checkbox: {
    minWidth: 'auto',
  },
})

type Selection = {
  selected: boolean | 'mixed'
  toggle: () => void
}

function getSelection(props: {
  shapes: AnnotShape[]
  editor: Editor
}): Selection {
  const { shapes, editor } = props

  /** Shapes of this group */
  const groupIds = shapes.map(shape => shape.id)
  /** Selected shapes of the editor */
  const editorIds = editor.getSelectedShapes().filter(isAnnotShape).map(shape => shape.id)
  /** Selected shapes of this group */
  const selectedIds = getArraysIntersect(groupIds, editorIds)

  const some = selectedIds.length > 0
  const all = selectedIds.length === groupIds.length

  const toggle: Selection['toggle'] = () => {
    const ids = all
      ? getArraysDifferent(editorIds, groupIds) // un-select all
      : getArraysUnion([editorIds, groupIds]) // select all
    editor.select(...ids)
  }

  return { selected: all ? true : some ? 'mixed' : false, toggle }
}

export const AnnotOverviewSelect = track((props: {
  shapes: AnnotShape[]
}): JSX.Element => {
  const { shapes } = props

  const editor = useEditor()
  const s = useStyles()

  const { toggle, selected } = getSelection({ shapes, editor })

  return (
    <Checkbox
      className={s.checkbox}
      checked={selected}
      onChange={(event) => {
        event.stopPropagation()
        toggle()
      }}
    />
  )
})
