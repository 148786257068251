import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { createSegmentGeoTool } from '../geo/tool'
import { createSegmentExtensionShape } from './create'

export const SEGMENT_EXTENSION_TOOL_ID = 'segment-extension'

const toAnnot: GeoToAnnot = (props) => {
  const { geo, prev } = props

  const box = new Box(geo.x, geo.y, geo.props.w, geo.props.h)

  const piece = createSegmentExtensionShape({
    center: box.center,
    id: geo.id,
    color: prev?.props.color ?? null,
    group: prev?.meta.group ?? null,
    mm: null,
    interactive: 'ByManual',
  })

  return piece
}

export function createSegmentExtensionTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  const id = SEGMENT_EXTENSION_TOOL_ID
  return createSegmentGeoTool({ id, setAttrs, toAnnot })
}
