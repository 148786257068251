import { ToolbarButton } from '@fluentui/react-components'
import { WeatherMoon20Regular, WeatherSunny20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { t } from '../../util/intl/t'
import { useSetting } from '../setting'

export function SettingThemeToggle(): ReactElement {
  const { setting, updateSetting } = useSetting()

  return (
    <ToolbarButton
      icon={setting.theme === 'dark'
        ? <WeatherSunny20Regular />
        : <WeatherMoon20Regular />}
      onClick={() => {
        const theme = setting.theme === 'dark' ? 'light' : 'dark'
        updateSetting({ theme })
      }}
    >
      {setting.theme === 'dark' ? t('app.theme.light') : t('app.theme.dark')}
    </ToolbarButton>
  )
}
