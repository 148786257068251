import { makeStyles } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import type { TLSelectionForegroundProps } from 'tldraw'
import { DefaultSelectionForeground, TldrawSelectionForeground, track, useEditor } from 'tldraw'
import { isPieceBoxShape } from '../../annot/piece/box/shape'
import { isPredictAreaShape } from '../../predict/area/shape'
import { isPredictGroupSampleShape } from '../../predict/group-sample/shape'
import { isPredictSampleShape } from '../../predict/sample/shape'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--selection-fg-stroke'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& .tl-selection__fg__outline': {
      stroke: 'var(--selection-fg-stroke) !important',
    },
  },
})
// @TODO: Review this after we have all shapes and tools.

/**
 * tldraw has 2 built-in SelectionForeground:
 * 1. "Tldraw..." is the full-featured one (used on tldraw.com) that provides
 *    all controls including resize handles. In fact, the resizing of GeoShape
 *    (after created) would not work without these controls.
 * 2. "Default..." is the minimum one with only a rectangle around the selected
 *    shapes.
 * Depend on the selected shape, we will render one or the other.
 *
 * Note that there is indeed a performance hit when switching from one to
 * another, but it's not much in practice since the wrapper itself (our
 * component here) does not change.
 */
export const EditorSelectionForeground = track(((
  props: TLSelectionForegroundProps,
): ReactElement | null => {
  const editor = useEditor()
  const s = useStyles()

  const shape = editor.getOnlySelectedShape()

  // If there is more than one selected shape, we should always use the minimum
  // selection to disable all controls. Note that this removes the control
  // handles, but does not prevent "drag to move" because that does not rely
  // on handles but on the shape itself.
  //
  // Technically, this also covers the case when there is no selection.
  if (shape === null)
    return <DefaultSelectionForeground {...props} />

  // Even when there is _only_ one selected shape, we only show the controls
  // in specific cases (e.g., PredictShape) because the majority of shapes
  // (i.e., AnnotShape) should still not have controls. (There is no "resize"
  // for annotations in practice.)
  if (false
    || isPredictAreaShape(shape)
    || isPredictSampleShape(shape)
    || isPredictGroupSampleShape(shape)
  )
    return <TldrawSelectionForeground {...props} />

  // Same as above. Additionally, piece shapes have a hidden bounding box
  // for the selected foreground
  if (false || isPieceBoxShape(shape)) {
    return (
      <div style={{ '--selection-fg-stroke': shape.props.color }} className={s.container}>
        <TldrawSelectionForeground {...props} />
      </div>
    )
  }
  // As a default, we should actually use a no selection rather than the
  // default one as the default one has a bouding rect that looks strange for
  // lines.
  return null
}))
