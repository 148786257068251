import { z } from 'zod'
import { INTL_DICT_EN } from './dict/en'
import { INTL_DICT_JA } from './dict/ja'
import { INTL_DICT_VI } from './dict/vi'
import { locale } from './locale/type'

type DictId = keyof typeof INTL_DICT_EN
export const LocaleSchema = z.enum(['en', 'ja', 'vi'])

const dict = {
  en: INTL_DICT_EN,
  ja: INTL_DICT_JA,
  vi: INTL_DICT_VI,
}
export function t(id: DictId) {
  const localeValidation = LocaleSchema.safeParse(locale)

  if (!localeValidation.success)
    return dict.en[id]

  return dict[localeValidation.data][id]
}
